import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import * as documentService from '../../../services/document';
import Popup from '../layout';
import FormFieldDrop from '../../formField/drop';
import FormRow from '../../formRow';

const PopUpDocument = ({ onConfirm, visible, setVisible, title, message }) => {
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState(null);
  const _message = message != null ? message : 'Selecione o tipo de documento?';

  const confirmDocument = () => {
    onConfirm(document.Id);
  };

  const loadDocuments = async () => {
    const { data } = await documentService.getAll();
    setDocuments(data);
    setDocument(data[0]);
    return data;
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  return (
    <Popup
      title={title}
      width={700}
      visible={visible}
      setVisible={setVisible}
      confirmText="Sim"
      cancelText="Não"
      onConfirm={confirmDocument}
    >
      <span style={{ marginTop: '10px' }}>{_message}</span>
      <FormRow withShrink>
        <FormFieldDrop
          titleLabel="Tipo de Documento"
          defaultValueSelect="Selecione..."
          onChangeValue={(val) => {
            setDocument(val.value);
          }}
          value={document}
          className="fill-50-field"
          infosSelect={documents}
          dataItemKey="Id"
          textField="Nome"
          required
        />
      </FormRow>
    </Popup>
  );
};

PopUpDocument.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpDocument;
