import PropTypes from 'prop-types';
import React from 'react';
import './style.css';

const HelpCard = ({ title, logo, children }) => (
  <div className="container">
    <div className="header">
      <img src={logo} alt="Logo" className="helpcard-image" />
      <span className="Title">{title}</span>
    </div>
    {children}
  </div>
);

HelpCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  logo: PropTypes.string.isRequired
};
export default HelpCard;
