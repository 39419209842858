import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useRef } from 'react';
import './styles.css';

const DropDown = ({
  onChangeValue,
  defaultValueSelect,
  infosSelect,
  className,
  disabled,
  value,
  textField,
  dataItemKey,
  required,
  loadData,
  serverFiltering,
  ...others
}) => {
  const [data, setData] = useState(serverFiltering ? [] : infosSelect);
  const [loading, setLoading] = useState(false);
  const pendingRequest = useRef();
  const requestStarted = useRef(false);
  const [filterSize, setFilterSize] = useState(false);

  const generateFilters = (filter) => {
    filter.value = filter.value.toLowerCase();
    const filters = [filter];
    const as = ['á', 'â', 'ã', 'a'];
    const es = ['é', 'ê', 'e'];
    const is = ['í', 'i'];
    const os = ['ó', 'ô', 'õ', 'o'];
    const us = ['ú', 'u'];
    const cs = ['ç', 'c'];
    const word = filter.value;
    as.forEach((item) => {
      const newWord = word.replace(/[áâãa]/, item);
      if (word !== newWord) filters.push({ ...filter, value: newWord });
    });
    es.forEach((item) => {
      const newWord = word.replace(/[éêe]/, item);
      if (word !== newWord) filters.push({ ...filter, value: newWord });
    });
    is.forEach((item) => {
      const newWord = word.replace(/[íi]/, item);
      if (word !== newWord) filters.push({ ...filter, value: newWord });
    });
    os.forEach((item) => {
      const newWord = word.replace(/[óôõo]/, item);
      if (word !== newWord) filters.push({ ...filter, value: newWord });
    });
    us.forEach((item) => {
      const newWord = word.replace(/[úu]/, item);
      if (word !== newWord) filters.push({ ...filter, value: newWord });
    });
    cs.forEach((item) => {
      const newWord = word.replace(/[çc]/, item);
      if (word !== newWord) filters.push({ ...filter, value: newWord });
    });
    return filters;
  };

  const fetchData = useCallback(async (filter) => {
    if (requestStarted.current) {
      clearTimeout(pendingRequest.current);
      pendingRequest.current = setTimeout(() => {
        fetchData(filter);
      }, 50);
      return;
    }
    requestStarted.current = true;
    setLoading(true);
    setData([]);
    const data = await loadData(filter);
    setData(data);
    setLoading(false);
    requestStarted.current = false;
  }, []);

  const filterData = (filter) => {
    const data = infosSelect.slice();
    const filters = generateFilters(filter);
    return filterBy(data, {
      logic: 'or',
      filters
    });
  };

  const filterChange = (event) => {
    if (serverFiltering) {
      const filter = event.filter.value;
      if (filter?.length <= 3) {
        setData([]);
        setFilterSize(false);
        return;
      }
      setFilterSize(true);
      fetchData(filter);
    } else setData(filterData(event.filter));
  };

  const listNoDataRender = (element) => {
    let msg = null;
    if (serverFiltering) {
      if (!filterSize) msg = <p>Digite pelo menos 4 caracteres</p>;
      else if (filterSize && loading) msg = <p>Procurando...</p>;
      else msg = <p>Nenhum dado foi encontrado</p>;
    } else msg = <p>Nenhum dado foi encontrado</p>;
    return React.cloneElement(element, { ...element.props }, msg);
  };

  const onFilterChange = serverFiltering ? fetchData : filterChange;
  return (
    <div className={`drop-field-wrapper ${className}`}>
      <div className="wrapper-input">
        <ComboBox
          className="drop-down-list-wrapper"
          onChange={onChangeValue}
          data={data}
          value={value}
          placeholder={defaultValueSelect}
          disabled={disabled}
          textField={textField}
          dataItemKey={dataItemKey}
          required={required}
          filterable={true}
          onFilterChange={onFilterChange}
          suggest={true}
          validationMessage="Preencha este campo."
          listNoDataRender={listNoDataRender}
          {...others}
        />
      </div>
    </div>
  );
};

DropDown.propTypes = {
  onChangeValue: PropTypes.func,
  defaultValueSelect: PropTypes.string,
  infosSelect: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  textField: PropTypes.string,
  dataItemKey: PropTypes.string,
  required: PropTypes.bool
};

DropDown.defaultProps = {
  onChangeValue: undefined,
  defaultValueSelect: undefined,
  infosSelect: [],
  className: '',
  disabled: false,
  textField: '',
  dataItemKey: '',
  required: false
};

export default DropDown;
