/* eslint no-restricted-syntax: 0 */
import React, { useContext, useEffect, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../../auth/AuthContext';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import PopupProductClass from '../../../components/popUps/popUpProductClass';
import PopupMeasureUnit from '../../../components/popUps/popUpMeasureUnit';
import PopUpTaxClass from '../../../components/popUps/popUpTaxClass';
import PopUpShoppingList from '../../../components/popUps/popUpShoppingList';
import FormFieldDrop from '../../../components/formField/drop';
import GenericField from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import FormFieldCheckbox from '../../../components/formField/checkbox';
import ToggleExpense from '../../../components/toggleInvoice';
import Label from '../../../components/formField/components/label';
import GridInline from '../../../components/grid';
import DateCell from '../../../components/grid/dateCell';
import NumberCell from '../../../components/grid/numberCell';
import { useForms } from '../../../hooks';
import { successNotification, errorNotification } from '../../../components/notification';
import LoadScreen from '../../load';
import Image from '../../../components/image';
import PopUpFiles from '../../../components/popUps/popUpFiles';
import * as planAccountService from '../../../services/planAccount';
import * as centerCostService from '../../../services/centerCost';
import * as productService from '../../../services/product';
import * as serviceService from '../../../services/service';
import * as taxClassService from '../../../services/taxClass';
import './styles.css';
import PopUpSalesList from '../../../components/popUps/popUpSalesList';
import { mimeType, heightByType, isImage } from '../../../utils/download';

const initialForm = {
  Codigo: null,
  Descricao: null,
  DescricaoComp: null,
  NCM: null,
  GTIN: null,
  CEST: null,
  Largura: null,
  Altura: null,
  Comprimento: null,
  PesoBruto: null,
  PesoLiquido: null,
  Caracteristica: null,
  Descontinuar: false
};

const ProductRegistration = () => {
  let { id } = useParams();
  const history = useHistory();
  const { walletSelected } = useContext(AuthContext);
  const [form, updateProperty, setForm, updateFormValue] = useForms(initialForm);
  const [shoppingPlanAccount, setShoppingPlanAccount] = useState(null);
  const [shoppingPlanAccounts, setShoppingPlanAccounts] = useState([]);
  const [shoppingCenterCost, setShoppingCenterCost] = useState(null);
  const [shoppingCenterCosts, setShoppingCenterCosts] = useState([]);
  const [salePlanAccount, setSalePlanAccount] = useState(null);
  const [salePlanAccounts, setSalePlanAccounts] = useState([]);
  const [saleCenterCost, setSaleCenterCost] = useState(null);
  const [saleCenterCosts, setSaleCenterCosts] = useState([]);
  const [taxClass, setTaxClass] = useState(null);
  const [taxClasses, setTaxClasses] = useState([]);
  const [productClass, setProductClass] = useState(null);
  const [productClasses, setProductClasses] = useState([]);
  const [measureUnit, setMeasureUnit] = useState(null);
  const [measureUnits, setMeasureUnits] = useState([]);
  const [discontinue, setDiscontinue] = useState(false);
  const [shoppingList, setShoppingList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [simplesNacional, setSimplesNacional] = useState(false);
  const [urlImage, setUrlImage] = useState(null);
  const [files, setFiles] = useState([]);
  const [html, setHtml] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const [loadingUnit, setLoadingUnit] = useState(false);
  const [loadingProductClass, setLoadingProductClass] = useState(false);
  const [loadingClassifications, setLoadingClassifications] = useState(false);

  const [popUpAddProductClass, setPopUpAddProductClass] = useState(false);
  const [popUpEditProductClass, setPopUpEditProductClass] = useState(false);
  const [popUpAddMeasureUnit, setPopUpAddMeasureUnit] = useState(false);
  const [popUpEditMeasureUnit, setPopUpEditMeasureUnit] = useState(false);
  const [popUpTaxClass, setPopUpTaxClass] = useState(false);
  const [popUpShoppingList, setPopUpShoppingList] = useState(false);
  const [popUpSalesList, setPopUpSalesList] = useState(false);
  const [popUpFiles, setPopUpFiles] = useState(false);

  const loadPlanAccounts = async () => {
    const data = await planAccountService.getAllExpensesRevenues();
    const expenses = data.filter((item) => item.NomeTipoPlanoDeContas === 'Despesa');
    const revenues = data.filter((item) => item.NomeTipoPlanoDeContas === 'Receita');
    setShoppingPlanAccounts(expenses);
    setSalePlanAccounts(revenues);
    return data;
  };

  const loadCenterCosts = async () => {
    const { data } = await centerCostService.getAll();
    setShoppingCenterCosts(data);
    setSaleCenterCosts(data);
    return data;
  };

  const loadTaxClasses = async () => {
    const { data } = await taxClassService.getAll();
    setTaxClasses(data);
    return data;
  };

  const loadProductClasses = async (productClass) => {
    setLoadingProductClass(true);
    const { data } = await productService.getProductClasses();
    setProductClasses(data);
    setProductClass(productClass);
    setLoadingProductClass(false);
    return data;
  };

  const loadMeasureUnits = async (measureUnit) => {
    setLoadingUnit(true);
    const { data } = await serviceService.getMeasureUnits();
    setMeasureUnits(data);
    setMeasureUnit(measureUnit);
    setLoadingUnit(false);
    return data;
  };

  const addShoppingList = (item) => {
    const id = shoppingList.length > 0 ? shoppingList[shoppingList.length - 1].Id + 1 : 1;
    const newItem = {
      Id: id,
      ...item
    };
    const newShoppingList = [...shoppingList, newItem];
    setShoppingList(newShoppingList);
  };

  const addSalesList = (item) => {
    const id = salesList.length > 0 ? salesList[shoppingList.length - 1].Id + 1 : 1;
    const newItem = {
      Id: id,
      ...item
    };
    const newSalesList = [...salesList, newItem];
    setSalesList(newSalesList);
  };

  const redirectToProducts = (id) => {
    if (id) history.push(`/products/${id}`);
    else history.push(`/products`);
  };

  const showFile = async (file) => {
    setHtml(null);
    const url = window.URL.createObjectURL(file.getRawFile());
    const height = heightByType(file.extension);
    const type = mimeType(file.extension);
    let html = <p>Não há pré-visualização para o arquivo selecionado</p>;
    if (type) {
      html = (
        <object data={url} type={type} width="100%" height={height}>
          <p>Não há pré-visualização para o arquivo selecionado</p>
        </object>
      );
    }
    setHtml(html);
  };

  const createImages = async (id) => {
    const attachRequests = files.map((file) =>
      productService.createFile(id, file.getRawFile(), file.Main)
    );
    const attachResponses = await Promise.all(attachRequests);

    const isAllRequestsRight = attachResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    if (!isAllRequestsRight) {
      const erro = ['Houve um erro ao tentar anexar uma ou mais arquivos:'];
      attachResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      return erro;
    }
    return null;
  };

  const onItemChangeShopping = (item) => {
    item.ValorTotal = item.Quantidade * item.Valor - item.Desconto;
    return item;
  };

  useEffect(async () => {
    if (walletSelected?.CarteiraBusiness?.SimplesNacional || walletSelected?.CarteiraBusiness?.MEI)
      setSimplesNacional(true);
    setLoadingGeneral(true);
    setLoadingClassifications(true);
    if (id && id !== '0') {
      const response = await productService.getById(id);
      const product = response.data;
      // ############## Load General ##############
      setForm({
        Codigo: product.Codigo,
        Descricao: product.Descricao,
        NCM: product.NCM,
        GTIN: product.GTIN,
        CEST: product.CEST,
        DescricaoComp: product.DescricaoComp,
        Largura: product.Largura,
        Altura: product.Altura,
        Comprimento: product.Comprimento,
        PesoBruto: product.PesoBruto,
        PesoLiquido: product.PesoLiquido,
        Caracteristica: product.Caracteristica,
        ImagemPrincipal: product.ImagemPrincipal
      });
      setDiscontinue(product.Descontinuar);
      const productClasses = await loadProductClasses();
      let itemProductClass = productClasses.find((item) => item.Id === product.ClasseProdutoId);
      itemProductClass = {
        ...itemProductClass,
        Editavel: true
      };
      setProductClass(itemProductClass);
      const measureUnits = await loadMeasureUnits();
      let itemMeasureUnit = measureUnits.find((item) => item.Id === product.UnidadeMedidaId);
      itemMeasureUnit = {
        ...itemMeasureUnit,
        Editavel: true
      };
      setMeasureUnit(itemMeasureUnit);
      const images = await productService.getFile(id);
      const image = images.find((item) => item.name === product.ImagemPrincipal);
      if (image) {
        const url = window.URL.createObjectURL(image.getRawFile());
        setUrlImage(url);
      } else {
        setUrlImage(null);
      }
      setLoadingGeneral(false);

      // ############## Load Classifications ##############
      let idShopping = 1;
      const shoppingList = product.ProdutoCompra.map((item) => ({
        ...item,
        Id: idShopping++,
        DataAtualizacao: item.DataAtualizacao ? new Date(item.DataAtualizacao) : null
      }));
      setShoppingList(shoppingList);
      setSalesList(product.ProdutoVenda);
      const planAccounts = await loadPlanAccounts();
      const centerCosts = await loadCenterCosts();
      const taxClasses = await loadTaxClasses();
      let itemPlanAccount = planAccounts.find((item) => item.Id === product.PlanoDeContasCompraId);
      let itemCenterCost = centerCosts.find((item) => item.Id === product.CentroDeCustosCompraId);
      setShoppingPlanAccount(itemPlanAccount);
      setShoppingCenterCost(itemCenterCost);
      itemPlanAccount = planAccounts.find((item) => item.Id === product.PlanoDeContasVendaId);
      itemCenterCost = centerCosts.find((item) => item.Id === product.CentroDeCustosVendaId);
      setSalePlanAccount(itemPlanAccount);
      setSaleCenterCost(itemCenterCost);
      const taxClass = taxClasses.find((item) => item.Id === product.ClasseDeImpostosId);
      setTaxClass(taxClass);
      setLoadingClassifications(false);
    } else {
      // ############## Load General ##############
      await loadProductClasses();
      await loadMeasureUnits();
      await loadTaxClasses();
      setLoadingGeneral(false);

      // ############## Load Classifications ##############
      const planAccounts = await loadPlanAccounts();
      const centerCosts = await loadCenterCosts();
      setLoadingClassifications(false);
    }
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const bodyForm = {
      ...form,
      Descontinuar: discontinue,
      PlanoDeContasCompraId: shoppingPlanAccount?.Id,
      CentroDeCustosCompraId: shoppingCenterCost?.Id,
      PlanoDeContasVendaId: salePlanAccount?.Id,
      CentroDeCustosVendaId: saleCenterCost?.Id,
      ClasseDeImpostosId: taxClass?.Id,
      ClasseProdutoId: productClass?.Id,
      UnidadeMedidaId: measureUnit?.Id,
      ProdutoCompra: shoppingList,
      ProdutoVenda: salesList
    };
    let response = null;
    if (id && id !== '0') {
      response = await productService.update(id, bodyForm);
      if (response.status === 200) {
        successNotification('Produto atualizado com sucesso!');
        redirectToProducts(id);
      } else {
        const { data } = response;
        if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
        else errorNotification(data);
      }
    } else {
      response = await productService.create(bodyForm);
      if (response.status === 200) {
        successNotification('Produto criado com sucesso!');
        const { data } = response;
        id = data;
        const erro = await createImages(id);
        if (erro) errorNotification(erro);
        redirectToProducts(id);
      } else {
        const { data } = response;
        if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
        else errorNotification(data);
      }
    }
    setLoading(false);
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      {id && id !== '0' ? <h1>Atualizar Produto</h1> : <h1>Criar Produto</h1>}
      <form onSubmit={submitForm}>
        <ToggleExpense title="Dados Gerais" startExpanded>
          {loadingGeneral ? (
            <LoadScreen />
          ) : (
            <>
              <div className="product-container">
                <div className="product-esquerda">
                  <FormRow>
                    <GenericField
                      titleLabel="Código Interno"
                      classNameWrapper="fill-50-field"
                      valueInput={form.Codigo}
                      onChangeValue={updateProperty}
                      name="Codigo"
                      msg="Código atribuido internamente ao produto. Exemplos: S-001, 001/1, 002 etc."
                      enableInfo
                    />
                    <FormFieldDrop
                      titleLabel="Classe do Produto"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        const productClass = val.value;
                        if (productClass == null) setProductClass(initialForm);
                        else {
                          productClass.Editavel = true;
                          setProductClass(productClass);
                        }
                      }}
                      value={productClass}
                      className="fill-50-field"
                      infosSelect={productClasses}
                      dataItemKey="Id"
                      textField="Nome"
                      enablePencil={productClass?.Editavel}
                      enableIcon
                      onClickIcon={() => setPopUpAddProductClass(true)}
                      onClickPencil={() => setPopUpEditProductClass(true)}
                    />
                  </FormRow>
                  <FormRow>
                    <GenericField
                      titleLabel="Descrição do Produto"
                      classNameWrapper="fill-100-field"
                      isTextArea
                      valueInput={form.Descricao}
                      onChangeValue={(val) => {
                        updateFormValue('Descricao', val.value);
                      }}
                      required
                    />
                  </FormRow>
                  <FormRow>
                    <GenericField
                      titleLabel="Complemento da Descrição do Produto"
                      classNameWrapper="fill-100-field"
                      isTextArea
                      valueInput={form.DescricaoComp}
                      onChangeValue={(val) => {
                        updateFormValue('DescricaoComp', val.value);
                      }}
                    />
                  </FormRow>
                  <FormRow withShrink>
                    <FormFieldDrop
                      titleLabel="Unidade de Medida"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        const measureUnit = val.value;
                        if (measureUnit == null) setMeasureUnit(initialForm);
                        else {
                          measureUnit.Editavel = true;
                          setMeasureUnit(measureUnit);
                        }
                      }}
                      value={measureUnit}
                      className="fill-25-field"
                      infosSelect={measureUnits}
                      dataItemKey="Id"
                      textField="Nome"
                      enablePencil={measureUnit?.Editavel}
                      enableIcon
                      onClickIcon={() => setPopUpAddMeasureUnit(true)}
                      onClickPencil={() => setPopUpEditMeasureUnit(true)}
                    />

                    <GenericField
                      titleLabel="NCM"
                      classNameWrapper="fill-25-field"
                      msg="Nomenclatura Comum do Mercosul (NCM). Informe-se sobre este número com seu fornecedor"
                      enableInfo
                      valueInput={form.NCM}
                      onChangeValue={updateProperty}
                      name="NCM"
                    />
                    <GenericField
                      titleLabel="GTIN (EAN Comercial)"
                      classNameWrapper="fill-25-field"
                      enableInfo
                      msg="Número Global do Item Comercial (GTIN), Informe-se sobre este número com seu fornecedor"
                      valueInput={form.GTIN}
                      onChangeValue={updateProperty}
                      name="GTIN"
                    />

                    <GenericField
                      titleLabel="CEST"
                      classNameWrapper="fill-25-field"
                      enableInfo
                      msg="Código Especificador de Substituição Tributária (CEST). Informe-se sobre este número com seu contador"
                      valueInput={form.CEST}
                      onChangeValue={updateProperty}
                      name="CEST"
                    />
                  </FormRow>
                </div>
                <div className="product-direita">
                  <FormRow>
                    <Image
                      backgroundColor="white"
                      textAvatar="Imagem para o produto (240x240px)"
                      imageSrc={urlImage}
                      imageAlt="Imagem para o produto"
                      className="fill-50-field"
                      height={240}
                      width={240}
                    />
                    {/* <div className="anexo-direita">{html}</div> */}
                  </FormRow>
                  <FormRow>
                    <OrangeButton
                      onClick={() => {
                        setPopUpFiles(true);
                      }}
                    >
                      Imagens
                    </OrangeButton>
                  </FormRow>
                </div>
              </div>
            </>
          )}
        </ToggleExpense>
        <ToggleExpense title="Informações de Compra" startExpanded>
          {loadingClassifications ? (
            <LoadScreen />
          ) : (
            <>
              <FormRow>
                <GridInline
                  data={shoppingList}
                  setData={setShoppingList}
                  deleteColumn={true}
                  onItemChange={onItemChangeShopping}
                >
                  <GridColumn field="Codigo" title="Código" />
                  <GridColumn field="PessoaNome" title="Fornecedor" editable={false} />
                  <GridColumn
                    field="DataAtualizacao"
                    title="Data de Atualização"
                    editable={true}
                    cell={DateCell}
                  />
                  <GridColumn field="MoedaNome" title="Moeda" editable={false} />
                  <GridColumn
                    field="Quantidade"
                    title="Quantidade"
                    editor="numeric"
                    cell={NumberCell}
                    editable={true}
                  />
                  <GridColumn
                    field="Valor"
                    title="Preço de Compra"
                    editor="numeric"
                    cell={NumberCell}
                    editable={true}
                  />
                  <GridColumn
                    field="Desconto"
                    title="Desconto (valor)"
                    editor="numeric"
                    cell={NumberCell}
                    editable={true}
                  />
                  <GridColumn
                    field="ValorTotal"
                    title="Total"
                    editable={false}
                    editor="numeric"
                    format="{0:n2}"
                  />
                </GridInline>
              </FormRow>
              <FormRow justifyEnd space10>
                <OrangeButton onClick={() => setPopUpShoppingList(true)}>Adicionar</OrangeButton>
              </FormRow>
              <FormRow withShrink>
                <div className="label-field">
                  <Label>Classificação Padrão de Compra</Label>
                </div>
              </FormRow>
              <FormRow withShrink>
                <FormFieldDrop
                  titleLabel="Plano de Contas"
                  defaultValueSelect="Selecione..."
                  onChangeValue={(val) => {
                    setShoppingPlanAccount(val.value);
                  }}
                  value={shoppingPlanAccount}
                  className="fill-50-field"
                  infosSelect={shoppingPlanAccounts}
                  dataItemKey="Id"
                  textField="Nome"
                />
                <FormFieldDrop
                  titleLabel="Centro de Custos"
                  defaultValueSelect="Selecione..."
                  onChangeValue={(val) => {
                    setShoppingCenterCost(val.value);
                  }}
                  value={shoppingCenterCost}
                  className="fill-50-field"
                  infosSelect={shoppingCenterCosts}
                  dataItemKey="Id"
                  textField="Nome"
                />
              </FormRow>
            </>
          )}
        </ToggleExpense>
        <ToggleExpense title="Informações de Venda" startExpanded>
          {loadingClassifications ? (
            <LoadScreen />
          ) : (
            <>
              <FormRow>
                <GridInline data={salesList} setData={setSalesList} deleteColumn={true}>
                  <GridColumn field="ListaVenda" title="Lista de Venda" />
                  <GridColumn field="MoedaNome" title="Moeda" editable={false} />
                  <GridColumn
                    field="Valor"
                    title="Preço de Venda"
                    editor="numeric"
                    cell={NumberCell}
                    editable={true}
                  />
                </GridInline>
              </FormRow>
              <FormRow justifyEnd space10>
                <OrangeButton onClick={() => setPopUpSalesList(true)}>Adicionar</OrangeButton>
              </FormRow>
              {!simplesNacional && (
                <FormRow>
                  <FormFieldDrop
                    titleLabel="Classe de Impostos"
                    infosSelect={taxClasses}
                    onChangeValue={(event) => {
                      setTaxClass(event.value);
                    }}
                    value={taxClass}
                    defaultValueSelect="Selecione..."
                    className="fill-25-field"
                    dataItemKey="Id"
                    textField="Nome"
                  />
                  <div className="side-button">
                    <OrangeButton onClick={() => setPopUpTaxClass(true)}>
                      Visualizar Classe de Impostos
                    </OrangeButton>
                  </div>
                </FormRow>
              )}
              <FormRow withShrink>
                <div className="label-field">
                  <Label>Classificação Padrão de Venda</Label>
                </div>
              </FormRow>
              <FormRow withShrink>
                <FormFieldDrop
                  titleLabel="Plano de Contas"
                  defaultValueSelect="Selecione..."
                  onChangeValue={(val) => {
                    setSalePlanAccount(val.value);
                  }}
                  value={salePlanAccount}
                  className="fill-50-field"
                  infosSelect={salePlanAccounts}
                  dataItemKey="Id"
                  textField="Nome"
                />
                <FormFieldDrop
                  titleLabel="Centro de Custos"
                  defaultValueSelect="Selecione..."
                  onChangeValue={(val) => {
                    setSaleCenterCost(val.value);
                  }}
                  value={saleCenterCost}
                  className="fill-50-field"
                  infosSelect={saleCenterCosts}
                  dataItemKey="Id"
                  textField="Nome"
                />
              </FormRow>
            </>
          )}
        </ToggleExpense>
        <ToggleExpense
          title="Informações Adicionais do Produto (Peso, dimensões, etc.)"
          startExpanded
        >
          {loadingGeneral ? (
            <LoadScreen />
          ) : (
            <>
              <FormRow>
                <GenericField
                  titleLabel="Largura (cm)"
                  classNameWrapper="fill-16-field"
                  valueInput={form.Largura}
                  onChangeValue={updateProperty}
                  name="Largura"
                  typeNumber
                />
                <GenericField
                  titleLabel="Altura (cm)"
                  classNameWrapper="fill-16-field"
                  valueInput={form.Altura}
                  onChangeValue={updateProperty}
                  name="Altura"
                  typeNumber
                />
                <GenericField
                  titleLabel="Comprimento (cm)"
                  classNameWrapper="fill-16-field"
                  valueInput={form.Comprimento}
                  onChangeValue={updateProperty}
                  name="Comprimento"
                  typeNumber
                />
                <GenericField
                  titleLabel="Peso Bruto (kg)"
                  classNameWrapper="fill-16-field"
                  valueInput={form.PesoBruto}
                  onChangeValue={updateProperty}
                  name="PesoBruto"
                  typeNumber
                />
                <GenericField
                  titleLabel="Peso Líquido (kg)"
                  classNameWrapper="fill-16-field"
                  valueInput={form.PesoLiquido}
                  onChangeValue={updateProperty}
                  name="PesoLiquido"
                  typeNumber
                />
              </FormRow>
              <FormRow>
                <GenericField
                  titleLabel="Características"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  valueInput={form.Caracteristica}
                  onChangeValue={(val) => {
                    updateFormValue('Caracteristica', val.value);
                  }}
                />
              </FormRow>
            </>
          )}
        </ToggleExpense>
        <FormRow>
          <FormFieldCheckbox
            label="Descontinuar Produto"
            classNameWrapper="fill-50-field"
            value={discontinue}
            onChange={(val) => {
              setDiscontinue(val.value);
            }}
          />
        </FormRow>
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit" name="salvar">
            Salvar
          </OrangeButton>
          <WhiteButton onClick={() => redirectToProducts(id)}>Cancelar</WhiteButton>
        </FormRow>
      </form>
      <PopupProductClass
        visible={popUpAddProductClass}
        setVisible={setPopUpAddProductClass}
        reloadProductClasses={loadProductClasses}
      />
      <PopupProductClass
        visible={popUpEditProductClass}
        setVisible={setPopUpEditProductClass}
        reloadProductClasses={loadProductClasses}
        entity={productClass}
        isEdit
      />
      <PopupMeasureUnit
        visible={popUpAddMeasureUnit}
        setVisible={setPopUpAddMeasureUnit}
        reloadMeasureUnits={loadMeasureUnits}
      />
      <PopupMeasureUnit
        visible={popUpEditMeasureUnit}
        setVisible={setPopUpEditMeasureUnit}
        reloadMeasureUnits={loadMeasureUnits}
        entity={measureUnit}
        isEdit
      />
      <PopUpTaxClass visible={popUpTaxClass} setVisible={setPopUpTaxClass} taxClass={taxClass} />
      <PopUpShoppingList
        visible={popUpShoppingList}
        setVisible={setPopUpShoppingList}
        add={addShoppingList}
      />
      <PopUpSalesList visible={popUpSalesList} setVisible={setPopUpSalesList} add={addSalesList} />
      <PopUpFiles
        visible={popUpFiles}
        setVisible={setPopUpFiles}
        title="Anexos"
        id={id && id !== '0' ? id : null}
        setExternalFiles={(files) => {
          setFiles(files);
          const image = files.find((item) => item.Main);
          setForm({
            ...form,
            ImagemPrincipal: image?.name
          });
          if (image) {
            const url = window.URL.createObjectURL(image.getRawFile());
            setUrlImage(url);
          } else {
            setUrlImage(null);
          }
        }}
        service={productService}
        main={!form.ImagemPrincipal ? 'semfotopadrao' : form.ImagemPrincipal}
      />
    </>
  );
};

export default ProductRegistration;
