import { axiosWalletRequest, METHOD } from '../api/Requests';
import { sortArrayByField } from '../utils/arrays';

export const getAll = async () => {
  const response = await axiosWalletRequest('ClasseDeImpostos', METHOD.GET);

  return {
    ...response,
    data: sortArrayByField(response.data, 'Nome')
  };
};

export const getById = (id) => axiosWalletRequest(`ClasseDeImpostos/${id}`, METHOD.GET);

export const create = (classTaxJson) => {
  return axiosWalletRequest('ClasseDeImpostos', METHOD.POST, classTaxJson);
};

export const update = (id, classTaxJson) => {
  return axiosWalletRequest(`ClasseDeImpostos/${id}`, METHOD.PUT, classTaxJson);
}

export const exclude = (id) => axiosWalletRequest(`ClasseDeImpostos/${id}`, METHOD.DELETE);
