import PropTypes from 'prop-types';
import './style.css';

//  TODO remover setinhas
const NumberInput = ({ className, setValue, value, ...others }) => (
  <input {...others} type="number" value={value} className={`${className} number-input`} />
);

NumberInput.propTypes = {
  className: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

NumberInput.defaultProps = {
  className: ''
};

export default NumberInput;
