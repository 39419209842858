import { axiosUserRequest, axiosBaseRequest, METHOD } from '../api/Requests';
import { setTokenOnStorage, getTokenFromStorage, TOKENS } from '../auth/Tokens';
import { getFinalValues } from '../utils/object';

export const login = (loginData) => axiosBaseRequest('Login', METHOD.POST, loginData);
export const logout = async (token) => {
  const config = { headers: { 'Content-Type': 'application/json' } };
  const { data } = await axiosBaseRequest('RefreshToken/InvalidarTokenPorToken', METHOD.PUT, token, config);
};

/**
 * Essa função não será necessária na aplicação final pois o login será feito externamente
 * @param {{
 *  Email: string,
 *  Senha: string,
 *  Token2Etapas?: boolean
 * }} loginData 
 */
export const signIn = async (loginData, setUser) => {
  const { data } = await login(loginData);

  setTokenOnStorage(TOKENS.USER.TOKEN, data.Token);
  setTokenOnStorage(
    TOKENS.USER.REFRESH_TOKEN,
    data.RefreshToken
  );

  setUser(data);
};

export const signOut = async (setUser) => {
  const walletToken = getTokenFromStorage(TOKENS.WALLET.REFRESH_TOKEN);
  await logout(walletToken);
  const userToken = getTokenFromStorage(TOKENS.USER.REFRESH_TOKEN);
  await logout(userToken);
  getFinalValues(TOKENS).forEach(token => localStorage.removeItem(token));
  setUser(null);
  window.location.reload();
};

export const selectWallet = async (wallet, setWalletSelected) => {
  const { data } = await axiosUserRequest('Login/SelecaoCarteira', METHOD.POST, { IdCarteira: wallet.IdCarteira });

  setTokenOnStorage(TOKENS.WALLET.TOKEN, data.AccessToken);
  setTokenOnStorage(
    TOKENS.WALLET.REFRESH_TOKEN,
    data.RefreshToken
  );

  setWalletSelected(wallet);
};
