import { Avatar } from '@progress/kendo-react-layout';
import { useState } from 'react';
import './style.css';

const Image = ({
  backgroundColor = 'white',
  textAvatar,
  className,
  imageSrc,
  imageAlt,
  height = 100,
  width = 270
}) => {
  const [size, setSize] = useState({ height, width });

  const adjustSize = (props) => {
    const { naturalHeight } = props.nativeEvent.target;
    const { naturalWidth } = props.nativeEvent.target;
    if (naturalWidth > naturalHeight) {
      const rate = width / naturalWidth;
      const newHeight = rate * naturalHeight;
      if (newHeight > height) {
        setSize({ height: `${height}px`, width: 'auto' });
      } else {
        setSize({ height: 'auto', width: `${width}px` });
      }
    } else {
      const rate = height / naturalHeight;
      const newWidth = rate * naturalWidth;
      if (newWidth > width) {
        setSize({ height: 'auto', width: `${width}px` });
      } else {
        setSize({ height: `${height}px`, width: 'auto' });
      }
    }
  };

  return (
    <div className={`image ${className}`}>
      <Avatar
        type="text"
        shape="square"
        style={{
          backgroundColor,
          height,
          width,
          minWidth: width
        }}
      >
        {imageSrc ? (
          <img src={imageSrc} alt={imageAlt} style={size} onLoad={adjustSize} />
        ) : (
          textAvatar
        )}
      </Avatar>
    </div>
  );
};

export default Image;
