import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpError from '../../components/popUps/popUpError';
import './styles.css';
import LoadScreen from '../load';
import * as productService from '../../services/product';
import { successNotification, warningNotification } from '../../components/notification';
import PopUpSettings from '../../components/popUps/popUpSettings';
import { productStatus, productStatusText } from '../../utils/status';

const Products = () => {
  const { id } = useParams();
  const editId = window.localStorage.getItem('EDIT_ID');
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [productsSelectedsIds, setProductsSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popUpConfirmDelete, setPopUpConfirmDelete] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [rowIndex, setRowIndex] = useState(0);
  const [popUpSettings, setPopUpSettings] = useState(false);

  const columns = [
    {
      field: 'Descontinuar',
      title: 'Situação',
      width: 60,
      cell: (props) => {
        const td = productStatus(props.dataItem.Descontinuar);
        return td;
      },
      columnMenuCheckbox: true
    },
    {
      field: 'Codigo',
      title: 'Código',
      columnMenuCheckbox: true
    },
    {
      field: 'ClasseProdutoNome',
      title: 'Classe de Produto',
      columnMenuCheckbox: true
    },
    {
      field: 'Descricao',
      title: 'Descrição',
      columnMenuCheckbox: true
    },
    {
      field: 'UnidadeMedidaNome',
      title: 'Unidade de Medida',
      columnMenuCheckbox: true
    }
  ];

  const seekScroll = async (data) => {
    if (editId != null) {
      const index = data.findIndex((item) => item.Id === editId);
      setRowIndex(index);
    } else {
      setRowIndex(data.length - 1);
    }
  };

  const fetchProducts = async () => {
    setLoading(true);
    let { data } = await productService.getAll();
    data = data.map((item) => ({
      ...item,
      Descontinuar: productStatusText(item.Descontinuar)
    }));
    setProducts(data);
    seekScroll(data);
    setProductsSelectedsIds([]);
    setLoading(false);
  };

  const excludeProducts = async () => {
    setLoading(true);
    const excludeProductsRequests = productsSelectedsIds.map((expenseSelected) =>
      productService.exclude(expenseSelected)
    );

    const excludeProductsResponses = await Promise.all(excludeProductsRequests);

    const isAllRequestsRight = excludeProductsResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    setPopUpConfirmDelete(false);
    if (isAllRequestsRight) successNotification('Produto(s) excluído(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir uma ou mais produtos:'];
      excludeProductsResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      setPopUpErrorTitle('Erro ao Excluir Produto');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchProducts();
  };

  useEffect(async () => {
    setLoading(true);
    await fetchProducts();
    setLoading(false);
  }, []);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="product-list">
        <h1>Produtos</h1>

        <div className="buttons-product-list">
          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
            url="/products/productRegistration/0"
          />

          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (productsSelectedsIds.length > 0) setPopUpConfirmDelete(true);
                  else warningNotification('Nenhum produto selecionado');
                },
                text: 'Excluir Produto'
              }
              // {
              //   id: INTERNAL_ITEMS.CONFIGURAR,
              //   onClick: () => setPopUpSettings(true),
              //   text: 'Configurar Tela'
              // }
            ]}
          />
        </div>
        <Table
          name="Products"
          data={products}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setProductsSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => {
            window.localStorage.setItem('EDIT_ID', Id);
            history.push(`/products/productRegistration/${Id}`);
          }}
          sortable={true}
          rowIndex={rowIndex}
        />
      </div>
      <PopUpConfirm
        onConfirm={excludeProducts}
        visible={popUpConfirmDelete}
        setVisible={setPopUpConfirmDelete}
        title="Confirmação"
        message="Você tem certeza que deseja excluir o(s) produtos(s) selecionado(s)?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpSettings
        onConfirm={(val) => {
          window.localStorage.setItem('PRODUCT_FILTER_TYPE', val);
        }}
        visible={popUpSettings}
        setVisible={setPopUpSettings}
        title="Preferências"
        filter={null}
      />
    </>
  );
};

export default Products;
