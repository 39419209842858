import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GridColumn } from '@progress/kendo-react-grid';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import { AuthContext } from '../../../auth/AuthContext';
import { OrangeButton, WhiteButton, SmallOrangeButton } from '../../../components/buttonsComponent';
import FormRow from '../../../components/formRow';
import GridInline from '../../../components/grid';
import * as accountService from '../../../services/account';
import * as cardService from '../../../services/card';
import DropDownCell from '../../../components/grid/dropDownCell';
import './style.css';
import LoadScreen from '../../load';
import PopUpCardTax from '../../../components/popUps/popUpCardTax';
import PopUpCardParcel from '../../../components/popUps/popUpCardParcel';
import { successNotification, errorNotification } from '../../../components/notification';
import { hasDuplicate } from '../../../utils/arrays';
import TrashIcon from '../../../assets/icone-excluir.svg';
import PopUpButton, { INTERNAL_BUTTON } from '../../../components/button';
import NumberCell from '../../../components/grid/numberCell';

const Card = () => {
  const { walletSelected, setWalletSelected } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const redirectToHomeList = () => history.push('/home');
  const [cardTaxes, setCardTaxes] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [popUpCardTax, setPopUpCardTax] = useState(false);
  const [popUpCardParcel, setPopUpCardParcel] = useState(false);
  const [cardTax, setCardTax] = useState(null);
  const [cardParcels, setCardParcels] = useState([]);
  const [parcels, setParcels] = useState([]);
  const formRef = React.useRef(null);
  const [operator, setOperator] = useState(null);

  const loadParcels = async () => {
    let { data } = await cardService.getParcels();
    data = data.map((item) => ({
      Id: parseInt(item.Id, 10),
      Nome: item.Descricao
    }));
    setParcels(data);
    return data;
  };

  const loadCardTaxes = async (accounts, parcels) => {
    const { data } = await cardService.getById(walletSelected.CarteiraBusiness.Id);
    const newCardTaxes = [];
    data.forEach((item) => {
      const account = accounts.find((element) => element.Id === item.ContaId);
      let newCardParcels = [];
      if (item.CartaoParcela) {
        let id = 0;
        newCardParcels = item.CartaoParcela.map((item2) => {
          id++;
          const ParcelaInicio = parcels.find((element) => element.Id === item2.ParcelaInicio);
          const ParcelaFim = parcels.find((element) => element.Id === item2.ParcelaFim);
          return {
            ...item2,
            Id: id,
            ParcelaInicio,
            ParcelaFim
          };
        });
      }
      const cardTax = {
        ...item,
        ContaPagamento: account,
        CartaoParcela: newCardParcels
      };
      newCardTaxes.push(cardTax);
    });
    setCardTaxes(newCardTaxes);
    return newCardTaxes;
  };

  const loadAccounts = async () => {
    const data = await accountService.getReceivingLocal();
    setAccounts(data);
    return data;
  };

  const addCardTax = async () => {
    setLoading(true);
    await loadCardTaxes(accounts, parcels);
    setLoading(false);
  };

  const addCardParcel = async () => {
    setLoading(true);
    await loadCardTaxes(accounts, parcels);
    setLoading(false);
  };

  const CommandCell = (props) => {
    const { dataItem } = props;
    return (
      <td className="k-command-cell" style={{ textAlign: 'center' }}>
        <div style={{ width: '100px', margin: 'auto' }}>
          <OrangeButton
            onClick={() => {
              setPopUpCardParcel(true);
              setCardTax(dataItem);
              if (dataItem.CartaoParcela != null) setCardParcels(dataItem.CartaoParcela);
              else setCardParcels([]);
            }}
          >
            Configurar
          </OrangeButton>
        </div>
      </td>
    );
  };

  const GridCommandCell = (props) => <CommandCell {...props} />;

  useEffect(async () => {
    setLoading(true);
    const accounts = await loadAccounts();
    const parcels = await loadParcels();
    const cardTaxes = await loadCardTaxes(accounts, parcels);
    setLoading(false);
  }, []);

  if (loading) return <LoadScreen />;
  return (
    <div id="card-list">
      <h1>Configurações de Venda por Cartão</h1>
      <div className="buttons-card-list">
        <div className="config-components-popup-button">
          <FloatingActionButton
            icon={INTERNAL_BUTTON.BUTTON_ADD}
            onClick={() => {
              setCardTax(null);
              setPopUpCardTax(true);
            }}
          />
        </div>
      </div>
      <FormRow>
        <GridInline
          data={cardTaxes}
          setData={setCardTaxes}
          onRowClick={async (element) => {
            const { dataItem } = element;
            setCardTax(dataItem);
            setPopUpCardTax(true);
          }}
        >
          <GridColumn field="Operadora" title="Estabelecimento/Operadora/Bandeira" />
          <GridColumn
            field="TaxaDebito"
            title="Taxa de Débito (%)"
            editor="numeric"
            cell={NumberCell}
          />
          <GridColumn
            field="DiaDebito"
            title="Dias p/ receber (Débito)"
            editor="numeric"
            format="{0:n0}"
          />
          <GridColumn
            field="TaxaCredito"
            title="Taxa de Crédito (%)"
            editor="numeric"
            cell={NumberCell}
          />
          <GridColumn
            field="DiaCredito"
            title="Dias p/ receber (Crédito)"
            editor="numeric"
            format="{0:n0}"
          />
          <GridColumn title="Parcelamento (Crédito)" cell={GridCommandCell} editable={false} />
          <GridColumn
            field="ContaPagamento"
            title="Local de Pagamento"
            cell={(props) => DropDownCell(props, accounts, 'Id', 'Nome')}
          />
        </GridInline>
      </FormRow>
      <FormRow justifyEnd space15 marginTop40>
        <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
      </FormRow>
      <PopUpCardTax
        addCardTax={addCardTax}
        visible={popUpCardTax}
        setVisible={setPopUpCardTax}
        accounts={accounts}
        cardTax={cardTax}
      />
      <PopUpCardParcel
        addCardParcel={addCardParcel}
        visible={popUpCardParcel}
        setVisible={setPopUpCardParcel}
        cardTax={cardTax}
        cardParcels={cardParcels}
        setCardParcels={setCardParcels}
      />
    </div>
  );
};

export default Card;
