import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getAll = () => axiosWalletRequest('Servico', METHOD.GET);

export const getById = (id) => axiosWalletRequest(`Servico/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('Servico', METHOD.POST, body);

export const exclude = (idCript) => axiosWalletRequest(`Servico/${idCript}`, METHOD.DELETE);

export const update = (idCript, body) => axiosWalletRequest(`Servico/${idCript}`, METHOD.PUT, body);

export const getMeasureUnits = async () => await axiosWalletRequest(`Servico/ListarUnidadeMedida`, METHOD.GET);

export const createMeasureUnit = async (body) => await axiosWalletRequest('Servico/CriarUnidadeMedida', METHOD.POST, body);

export const updateMeasureUnit = (body, id) => axiosWalletRequest(`Servico/AtualizarUnidadeMedida/${id}`, METHOD.PUT, body);

export const excludeMeasureUnit = (id) => axiosWalletRequest(`Servico/ExcluirUnidadeMedida/${id}`, METHOD.DELETE);