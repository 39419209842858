import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormFieldDrop from '../../../components/formField/drop';
import GenericField, { INTERNAL_ICON } from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import PopUpError from '../../../components/popUps/popUpError';
import ToggleInvoice from '../../../components/toggleInvoice';
import { useForms } from '../../../hooks';
import * as proposalService from '../../../services/proposal';
import { successNotification, errorNotification } from '../../../components/notification';
import LoadScreen from '../../load';

const initialProposal = {
  Nome: null,
  Introducao: null,
  Validade: null,
  Garantia: null,
  Entrega: null,
  Condicao: null,
  Observacao: null,
  InformacaoAdicional: null
};

const ProposalRegistration = () => {
  const { id } = useParams();
  const history = useHistory();
  const [form, updateProperty, setForm, updateFormValue] = useForms(initialProposal);
  const [loading, setLoading] = useState(true);

  const redirectToProposalList = () => history.push('/proposals');

  useEffect(async () => {
    setLoading(true);
    if (id) {
      const { data } = await proposalService.getById(id);
      setForm({
        Nome: data.Nome,
        Introducao: data.Introducao,
        Validade: data.Validade,
        Garantia: data.Garantia,
        Entrega: data.Entrega,
        Condicao: data.Condicao,
        Observacao: data.Observacao,
        InformacaoAdicional: data.InformacaoAdicional
      });
    }
    setLoading(false);
  }, []);

  const validation = async () => true;

  const submitForm = async (e) => {
    e.preventDefault();
    if (await validation()) {
      setLoading(true);
      const bodyForm = {
        Nome: form.Nome,
        Introducao: form.Introducao,
        Validade: form.Validade,
        Garantia: form.Garantia,
        Entrega: form.Entrega,
        Condicao: form.Condicao,
        Observacao: form.Observacao,
        InformacaoAdicional: form.InformacaoAdicional
      };
      let response = null;
      if (id) response = await proposalService.update(id, bodyForm);
      else response = await proposalService.create(bodyForm);
      if (response.status === 200) {
        successNotification('Proposta criada/atualizada com sucesso!');
        redirectToProposalList();
      } else {
        const { data } = response;
        errorNotification(data.MensagemUsuario);
      }
      setLoading(false);
    }
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      {id ? <h1>Atualizar Padrão de Proposta</h1> : <h1>Criar Padrão de Proposta</h1>}
      <form onSubmit={submitForm}>
        <FormRow>
          <GenericField
            titleLabel="Nome do Padrão"
            classNameWrapper="fill-100-field"
            valueInput={form.Nome}
            onChangeValue={updateProperty}
            name="Nome"
            required
            msg="Informe um nome para identificar e selecionar o padrão a ser utilizado em uma nova proposta. Ex.:  
            Proposta Padrão para Consultoria"
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Introdução"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Introducao}
            onChangeValue={(val) => {
              updateFormValue('Introducao', val.value);
            }}
            msg="Insira aqui uma apresentação inicial de sua proposta, que será enviada antes dos itens e valores cotados. Manter o campo em branco, caso não queira que a informação seja enviada na proposta.            Ex.: Agradecemos o contato e, conforme solicitado, enviamos proposta para nosso serviço de consultoria de gestão de seu negócio."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Validade"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Validade}
            onChangeValue={(val) => {
              updateFormValue('Validade', val.value);
            }}
            msg="Informe por quanto tempo a proposta será válida. Manter o campo em branco, caso não queira que a informação seja enviada na proposta. Ex.: Proposta válida por 10 (dez) dias."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Garantia"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Garantia}
            onChangeValue={(val) => {
              updateFormValue('Garantia', val.value);
            }}
            msg="Informe a garantia dos produtos ou serviços propostos. Manter o campo em branco, caso não queira que a informação seja enviada na proposta. Ex.: A garantia dos serviços propostos é de 30 (trinta) dias, contados a partir da entrega."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Prazos de Entrega"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Entrega}
            onChangeValue={(val) => {
              updateFormValue('Entrega', val.value);
            }}
            msg="Informar em quanto tempo os produtos ou serviços propostos serão entregues ao cliente. Manter o campo em branco, caso não queira que a informação seja enviada na proposta. Ex.: O serviço proposto será entre que 90 (noventa) dias."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Condições de Pagamento"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Condicao}
            onChangeValue={(val) => {
              updateFormValue('Condicao', val.value);
            }}
            msg="Informar as condições de pagamento disponíveis para o seu cliente. Manter o campo em branco, caso não queira que a informação seja enviada na proposta. Ex.: Preço em Reais, válidos para pagamento à vista ou em até 3(três) vezes sem juros. Formas de pagamento disponíveis: PIX (QR Code ou Chave PIX), boleto bancário, depósito em conta corrente, cartão de crédito (consulte bandeiras aceitas pelo estabelecimento)."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Observações"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Observacao}
            onChangeValue={(val) => {
              updateFormValue('Observacao', val.value);
            }}
            msg="Insira aqui observações relevantes a serem enviadas para seu cliente na proposta. Manter o campo em branco, caso não queira que a informação seja enviada na proposta. Ex.: Os serviços propostos serão executados em formato híbrido (parcialmente virtuais). Custos de viagens não estão inclusos nesta proposta."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Informações Adicionais e Finalização"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.InformacaoAdicional}
            onChangeValue={(val) => {
              updateFormValue('InformacaoAdicional', val.value);
            }}
            msg="Insira aqui todas as informações adicionais a serem enviadas na proposta, tais como detalhamento e descrições adicionais dos produtos ou serviços cotados, informações sobre a própria empresa e uma mensagem de finalização da proposta. Manter o campo em branco, caso não queira que a informação seja enviada na proposta. Ex.: Sobre o Serviço de Consultoria: A finalidade da consultoria é levantar as necessidades, por meio de diagnósticos e processos, identificar soluções e, então, recomendar ações de melhoria. Nossa Empresa é, há mais de X anos, referência em prestação de serviços de consultoria, atuando em todo o território nacional. Permanecemos à disposição para quaisquer dúvidas."
            enableInfo
          />
        </FormRow>
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit">Salvar</OrangeButton>
          <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
        </FormRow>
      </form>
    </>
  );
};

export default ProposalRegistration;
