import React, { useState, useEffect, useContext } from 'react';
import './styles.css';
import 'hammerjs';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartAxisDefaults,
  ChartSeriesItemTooltip
} from '@progress/kendo-react-charts';
import { Card, CardTitle, CardBody, CardActions, CardImage } from '@progress/kendo-react-layout';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import 'react-datepicker/dist/react-datepicker.css';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import caGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/pt/dateFields.json';
import numbers from 'cldr-numbers-full/main/pt/numbers.json';
import ptMessages from '../../utils/pt.json';
import { toMoney } from '../../utils/formatter';
import { Calendar } from '../../components/dateComponents';
import { AuthContext } from '../../auth/AuthContext';
import { errorNotification } from '../../components/notification';
import SaleDay from '../../assets/sale-day.svg';
import SaleWeek from '../../assets/sale-week.svg';
import SaleMonth from '../../assets/sale-month.svg';
import SaleYear from '../../assets/sale-year.svg';
import * as saleService from '../../services/sale';
import LoadScreen from '../load';
import { DateNow } from '../../utils/date';

load(likelySubtags, weekData, caGregorian, dateFields, numbers);
loadMessages(ptMessages, 'pt');

const initialDashboard = {
  VendaDiaria: 0,
  VendaSemanal: 0,
  VendaMensal: 0,
  VendaAnual: 0,
  VendasMensais: []
};

const Home = () => {
  const { walletSelected } = useContext(AuthContext);
  const [date, setDate] = useState(DateNow());
  const [chartData, setChartData] = useState([0]);
  const [chartMonths, setChartMonths] = useState([]);
  const [dashBoard, setDashboard] = useState(initialDashboard);
  const [loading, setLoading] = useState(true);

  const anualValues = async (values) => {
    const year = values?.length > 0 ? new Date(values[0].Data).getFullYear() : date.getFullYear();
    const chartLineData = values?.length > 0 ? Array(values.length) : [];
    let chartMonthDataFinal = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez'
    ];
    chartMonthDataFinal = chartMonthDataFinal.map((e) => `${e}`);
    values?.forEach((e) => {
      const data = new Date(e.Data);
      chartLineData[data.getMonth()] = e.Valor;
    });
    setChartData(chartLineData);
    setChartMonths(chartMonthDataFinal);
  };

  const loadSaleDashboard = async () => {
    const dateInterval = {
      DataInicio: date,
      DataFim: date
    };
    const { data } = await saleService.saleDashboard(dateInterval);
    setDashboard(data);
    return data;
  };

  useEffect(async () => {
    setLoading(true);
    if (walletSelected.CarteiraBusiness == null) {
      errorNotification(
        'Não é possível a utilização do sistema pois não existe um carteira Next Business associada a carteira do Next Finance.'
      );
    } else {
      const dashBoard = await loadSaleDashboard();
      if (dashBoard.VendasMensais) await anualValues(dashBoard.VendasMensais);
    }
    setLoading(false);
  }, [date]);

  const ChartContainer = ({ chartData }) => (
    <LocalizationProvider language="pt">
      <IntlProvider locale="pt">
        <Chart>
          <ChartAxisDefaults
            labels={{
              format: 'n2'
            }}
          />
          <ChartSeriesItemTooltip format="n2" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={chartMonths} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              color="#00B248"
              tooltip={{ visible: true }}
              type="column"
              data={chartData}
              markers={{ visible: false }}
            />
          </ChartSeries>
        </Chart>
      </IntlProvider>
    </LocalizationProvider>
  );

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <h1>Início</h1>
      <div className="buttons-home">
        <Calendar date={date} setDate={setDate} />
      </div>
      <div>
        <div className="k-card-deck">
          <Card orientation="horizontal" layout="end">
            <CardImage src={SaleDay} />
            <div className="k-vbox">
              <CardBody>
                <CardTitle style={{ color: '#5F6CAA' }}>Receitas Diárias</CardTitle>
              </CardBody>
              <CardActions layout="end">
                <CardTitle>{toMoney(dashBoard.VendaDiaria, true)}</CardTitle>
              </CardActions>
            </div>
          </Card>
          <Card orientation="horizontal" layout="end">
            <CardImage src={SaleWeek} />
            <div className="k-vbox">
              <CardBody>
                <CardTitle style={{ color: '#5DA57D' }}>Receitas Semanais</CardTitle>
              </CardBody>
              <CardActions layout="end">
                <CardTitle>{toMoney(dashBoard.VendaSemanal, true)}</CardTitle>
              </CardActions>
            </div>
          </Card>
          <Card orientation="horizontal" layout="end">
            <CardImage src={SaleMonth} />
            <div className="k-vbox">
              <CardBody>
                <CardTitle style={{ color: '#FF8213' }}>Receitas Mensais</CardTitle>
              </CardBody>
              <CardActions layout="end">
                <CardTitle>{toMoney(dashBoard.VendaMensal, true)}</CardTitle>
              </CardActions>
            </div>
          </Card>
          <Card orientation="horizontal" layout="end">
            <CardImage src={SaleYear} />
            <div className="k-vbox">
              <CardBody>
                <CardTitle style={{ color: '#00A6D2' }}>Receitas Anuais</CardTitle>
              </CardBody>
              <CardActions layout="end">
                <CardTitle>{toMoney(dashBoard.VendaAnual, true)}</CardTitle>
              </CardActions>
            </div>
          </Card>
        </div>
      </div>
      <div className="container-card">
        <h2 className="card-header" style={{ color: '#FF8213', borderBottomColor: '#FF8213' }}>
          Receitas Anuais
        </h2>
        <div className="card-content">
          <span className="valueInReal">Valores em [R$]</span>
          <ChartContainer chartData={chartData} />
        </div>
      </div>
    </>
  );
};

export default Home;
