import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

const RadioButton = ({ id, name, value, label, checked }) => (
  <label htmlFor={id} className="radio-label">
    <input id={id} type="radio" name={name} value={value} checked={checked} />

    <span className="radio-icon" />

    {label}
  </label>
);

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default RadioButton;
