export const compareArrays = (arr1, arr2) => {
  return arr1.length === arr2.length
    && arr1.reduce((acc, current, ind) => arr2[ind] === current && acc, true);
}

export const sortArrayByField = (arr, field) => arr.sort((a, b) => a[field].localeCompare(b[field]));


export const hasDuplicate = (arr, object, property) => {
  let valueArr = [];
  if (object && property) {
    valueArr = arr.map((item) => item[object][property]);
    return valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
  }
  valueArr = arr.map((item) => item[object]);
  return valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
};

export const maxValue = (arr, object, property) => {
  let valueArr = [];
  if (object && property) {
    valueArr = arr.map((item) => item[object][property]);
    return Math.max.apply(null, valueArr);
  }
  valueArr = arr.map((item) => item[object]);
  return Math.max.apply(null, valueArr);
};