import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import FormRow from '../../components/formRow';
import Table from '../../components/table';
import FormFieldDrop from '../../components/formField/drop';
import FormFieldDate from '../../components/formField/date';
import GridGroup from '../../components/gridGroup';
import { OrangeButton, WhiteButton } from '../../components/buttonsComponent';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpError from '../../components/popUps/popUpError';
import './styles.css';
import LoadScreen from '../load';
import * as expenseService from '../../services/expense';
import * as personService from '../../services/person';
import { successNotification, warningNotification } from '../../components/notification';
import PopUpSettings from '../../components/popUps/popUpSettings';
import { productStatus, productStatusText } from '../../utils/status';
import { ColumnMenu, ColumnMenuCheckbox } from '../../components/table/columnMenu';

const initialGroup = [
  {
    field: 'Descricao'
  }
];

const Shopping = () => {
  const { id } = useParams();
  const editId = window.localStorage.getItem('EDIT_ID');
  const history = useHistory();
  const [shopping, setShopping] = useState([]);
  const [shoppingSelectedsIds, setShoppingSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [popUpSettings, setPopUpSettings] = useState(false);
  const [supplier, setSupplier] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  const [filterButton, setFilterButton] = useState(false);
  const [reportButton, setReportButton] = useState(false);

  const fetchShopping = async () => {
    setLoading(true);
    let { data } = await expenseService.getShopping({
      PessoaId: supplier?.Id,
      DataInicial: initialDate,
      DataFinal: finalDate
    });
    data = data.map((item) => ({
      Descricao: item.DescricaoServProd,
      Fornecedor: item.Despesa.PessoaNome,
      Codigo: item.Codigo,
      UnidadeMedidaNome: item.UnidadeMedida,
      Data: new Date(item.Despesa.Data),
      Valor: item.Valor,
      Quantidade: item.Quantidade,
      Desconto: item.Desconto,
      ValorTotal: item.ValorTotal,
      Numero: item.Despesa.Numero
    }));
    setShopping(data);
    setShoppingSelectedsIds([]);
    setLoading(false);
  };

  const loadSuppliers = async (filter) => {
    const { data } = await personService.getBySupplierName(filter);
    return data;
  };

  const columnMenuCheckbox = (props) => <ColumnMenuCheckbox {...props} data={shopping} />;
  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="shopping-list">
        <h1>Análise de Compra</h1>
        <FormRow justifyEnd withoutMarginTop space10>
          {filterButton && (
            <div className="config-components-popup-button">
              <FloatingActionButton
                icon={INTERNAL_BUTTON.BUTTON_FILTER}
                onClick={() => {
                  setFilterButton(false);
                  setReportButton(true);
                }}
              />
            </div>
          )}
          {reportButton && (
            <div className="config-components-popup-button">
              <FloatingActionButton
                icon={INTERNAL_BUTTON.BUTTON_TABLE}
                onClick={() => {
                  setFilterButton(true);
                  setReportButton(false);
                }}
              />
            </div>
          )}
          <div className="buttons-shopping-list">
            <PopUpButton internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS} internalItems={[]} />
          </div>
        </FormRow>
        {!filterButton && (
          <>
            <FormRow withShrink>
              <FormFieldDrop
                titleLabel="Fornecedor"
                defaultValueSelect="Todos"
                onChangeValue={async (val) => {
                  if (val.value) {
                    await setSupplier(val.value);
                  }
                }}
                value={supplier}
                className="fill-100-field"
                loadData={loadSuppliers}
                serverFiltering={true}
                dataItemKey="Id"
                textField="Nome"
              />
            </FormRow>
            <FormRow withShrink>
              <FormFieldDate
                titleLabel="Data Inicial"
                value={initialDate}
                valueOnChange={(val) => {
                  setInitialDate(val);
                }}
                className="fill-25-field"
              />
              <FormFieldDate
                titleLabel="Data Final"
                value={finalDate}
                valueOnChange={(val) => {
                  setFinalDate(val);
                }}
                className="fill-25-field"
              />
            </FormRow>
            <FormRow justifyEnd space10>
              <OrangeButton
                onClick={() => {
                  setFilterButton(true);
                  setReportButton(false);
                  fetchShopping();
                }}
              >
                Filtrar
              </OrangeButton>
              <WhiteButton
                onClick={() => {
                  setSupplier(null);
                  setInitialDate(null);
                  setFinalDate(null);
                  setShopping([]);
                }}
              >
                Limpar
              </WhiteButton>
            </FormRow>
          </>
        )}
        {filterButton && (
          <GridGroup initialGroup={initialGroup} data={shopping}>
            <Column field="Descricao" title="Descrição" columnMenu={columnMenuCheckbox} />
            <Column field="Fornecedor" title="Fornecedor" columnMenu={columnMenuCheckbox} />
            <Column field="Codigo" title="Código" columnMenu={columnMenuCheckbox} />
            <Column field="UnidadeMedidaNome" title="Uni. Med." columnMenu={columnMenuCheckbox} />
            <Column
              field="Data"
              title="Data da Compra"
              editor="date"
              format="{0:dd/MM/yyyy}"
              columnMenu={ColumnMenu}
            />
            <Column
              field="Quantidade"
              title="Qtd"
              editor="numeric"
              format="{0:n2}"
              columnMenu={columnMenuCheckbox}
            />
            <Column
              field="Valor"
              title="Valor"
              editor="numeric"
              format="{0:n2}"
              columnMenu={columnMenuCheckbox}
            />
            <Column
              field="Desconto"
              title="Desconto"
              editor="numeric"
              format="{0:n2}"
              columnMenu={columnMenuCheckbox}
            />
            <Column
              field="ValorTotal"
              title="Valor Total"
              editor="numeric"
              format="{0:n2}"
              columnMenu={columnMenuCheckbox}
            />
          </GridGroup>
        )}
      </div>
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpSettings
        onConfirm={(val) => {
          window.localStorage.setItem('PRODUCT_FILTER_TYPE', val);
        }}
        visible={popUpSettings}
        setVisible={setPopUpSettings}
        title="Preferências"
        filter={null}
      />
    </>
  );
};

export default Shopping;
