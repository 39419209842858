import PropTypes from 'prop-types';
import React from 'react';
import Popup from '../layoutOnClick';

const PopUpConfirmResetAvatar = ({ resetAvatar, visible, setVisible, messageResetAvatar }) => {
  const message =
    messageResetAvatar != null
      ? messageResetAvatar
      : 'Você tem certeza que deseja excluir o seu avatar?';

  const confirmResetAvatar = () => {
    const errors = [];
    resetAvatar();
    return errors;
  };

  return (
    <Popup
      title="Confirmação"
      width={700}
      visible={visible}
      setVisible={setVisible}
      confirmText="Sim"
      cancelText="Não"
      onConfirm={confirmResetAvatar}
    >
      <span style={{ marginTop: '10px' }}>{message}</span>
    </Popup>
  );
};

PopUpConfirmResetAvatar.propTypes = {
  resetAvatar: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpConfirmResetAvatar;
