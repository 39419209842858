import PropTypes from 'prop-types';
import React from 'react';
import './style.css';

const SmallOrangeButton = ({ children, type, disable, ...other }) => {
  const className = 'small-orange-button small-box-button general-button';
  return (
    <button {...other} type="button" className={className} disabled={disable}>
      {children}
    </button>
  );
};

SmallOrangeButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  disable: PropTypes.bool
};

SmallOrangeButton.defaultProps = {
  type: 'button',
  disable: false
};

export default SmallOrangeButton;
