import * as React from 'react';
import { toMoney } from '../../utils/formatter';

export const TotalCell = (props, elements, field) => {
  const total = elements.reduce((acc, current) => acc + current[field], 0);
  return (
    <td colSpan={props.colSpan} style={{ textAlign: 'right' }}>
      {toMoney(total, true)}
    </td>
  );
};
export const CountCell = (props, elements) => {
  const total = elements?.length;
  return (
    <td colSpan={props.colSpan} style={{ textAlign: 'right' }}>
      Qtd: {total}
    </td>
  );
};
export const TotalValueCell = (props, total) => (
  <td colSpan={props.colSpan} style={{ textAlign: 'right' }}>
    {toMoney(total, true)}
  </td>
);
export const TotalTextCell = (props) => <td colSpan={props.colSpan}>Total</td>;
