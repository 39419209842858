const readerUrl = new FileReader();
const readerBinary = new FileReader();

export const getFileData = async ({ target: { files } }) => {
  const [file] = files;

  const dataUrl = new Promise((resolve) => {
    readerUrl.onload = ({ target: { result } }) => {
      resolve(result);
    };
  
    readerUrl.readAsDataURL(file);
  });

  const binaryRawFile = new Promise((resolve) => {
    readerBinary.onload = ({ target: { result } }) => {
      resolve(result);
    };
  
    readerBinary.readAsArrayBuffer(file);
  });

  const [url, binary] = await Promise.all([dataUrl, binaryRawFile]);

  return {
    type: file.type,
    size: file.size,
    url,
    binary
  }
};
