/* eslint object-shorthand: 0 */
/* eslint prefer-destructuring: 0 */
/* eslint operator-assignment: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
import './style.css';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import { GridColumn } from '@progress/kendo-react-grid';
import { errorNotification, successNotification, warningNotification } from '../../notification';
import Popup from '../layout';
import { mimeType, imageByType, saveFile, isImage } from '../../../utils/download';
import LoadScreen from '../../../view/load';
import GridInline from '../../grid';
import TransparentCard from '../../transparentCard';

const height = 479;
const width = 455;

const PopUpFiles = ({
  visible,
  setVisible,
  title,
  id,
  setExternalFiles,
  afterAttaching,
  service,
  main = null
}) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState({ height, width });
  const [url, setUrl] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [loadingDiv, setLoadingDiv] = useState(false);

  const adjustSize = (props) => {
    const { naturalHeight } = props.nativeEvent.target;
    const { naturalWidth } = props.nativeEvent.target;
    if (naturalWidth > naturalHeight) {
      const rate = width / naturalWidth;
      const newHeight = rate * naturalHeight;
      if (newHeight > height) {
        setSize({ height: `${height}px`, width: 'auto' });
      } else {
        setSize({ height: 'auto', width: `${width}px` });
      }
    } else {
      const rate = height / naturalHeight;
      const newWidth = rate * naturalWidth;
      if (newWidth > width) {
        setSize({ height: 'auto', width: `${width}px` });
      } else {
        setSize({ height: `${height}px`, width: 'auto' });
      }
    }
  };

  const showFile = async (dataItem) => {
    if (dataItem) {
      const _url = window.URL.createObjectURL(dataItem.getRawFile());
      setUrl(_url);
      setShowImage(isImage(dataItem.extension));
    }
  };

  const loadFiles = async (id) => {
    let files = await service.getFile(id);
    let image = files[0];
    if (main) {
      files = files.map((file) => ({
        ...file,
        Main: file.name === main
      }));
      image = files.find((file) => file.Main);
    }
    showFile(image);
    setFiles(files);
  };

  const createAttachs = async (id) => {
    setLoading(true);

    await service.excludeFile(id);

    const attachRequests = files.map((file) =>
      service.createFile(id, file.getRawFile(), file.Main)
    );

    const attachResponses = await Promise.all(attachRequests);

    const isAllRequestsRight = attachResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);

    if (isAllRequestsRight) {
      successNotification('Arquivos(s) salvo(s) com sucesso!');
      if (afterAttaching) afterAttaching();
    } else {
      const erro = ['Houve um erro ao tentar anexar uma ou mais arquivos:'];
      attachResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      errorNotification(erro);
    }
  };

  const onRowClick = async (e) => {
    const { dataItem } = e;
    showFile(dataItem);
  };

  const onItemEdit = async (dataItem) => {
    const name = dataItem.name;
    const file = dataItem.getRawFile();
    saveFile(name, file);
  };

  const headerCellRender = (td, props) => {
    if (props.field === 'Main') return <span className="k-link">Padrão</span>;
    return td;
  };

  const onSelectionChange = (event) => {
    const element = files.find((item) => item.Id === event.dataItem.Id);
    element.Main = !element.Main;
    const newData = files.map((item) => {
      if (item.Id !== event.dataItem.Id) {
        item.Main = false;
      }
      return item;
    });
    setFiles(newData);
    onRowClick(event);
  };

  useEffect(async () => {
    setUrl(null);
    if (id) {
      setLoading(true);
      await loadFiles(id);
      setLoading(false);
    }
  }, [id, visible]);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <Popup
        width={1024}
        title={title}
        visible={visible}
        setVisible={setVisible}
        cancelText="Cancelar"
        confirmText="Salvar"
        onConfirm={() => {
          if (id) {
            createAttachs(id);
            if (setExternalFiles) setExternalFiles(files);
          } else {
            if (setExternalFiles) setExternalFiles(files);
            warningNotification(
              'ATENÇÃO: Os arquivos só serão enviados quando o documento for salvo'
            );
          }
        }}
      >
        <div className="anexo-container">
          <div className="anexo-esquerda">
            <Upload
              batch={true}
              multiple={true}
              onAdd={(e) => {
                let i = 1;
                let newFiles = e.newState;
                newFiles = newFiles.map((file) => ({
                  ...file,
                  Id: i++,
                  Main: false
                }));
                const file = newFiles[0];
                newFiles = [...files, ...newFiles];
                newFiles = newFiles.map((file) => ({
                  ...file,
                  Id: i++
                }));
                setFiles(newFiles);
                if (file) showFile(file);
              }}
            />
            <TransparentCard
              className="selected-file-phrase"
              content="O tamanho máximo permitido é 5MB por arquivo."
            />
            <GridInline
              dataItemKey="name"
              data={files}
              setData={setFiles}
              deleteColumn={true}
              onItemDelete={() => {
                setUrl(null);
              }}
              style={{ height: 410 }}
              noRecords="Sem arquivos"
              onRowClick={onRowClick}
              editColumn={true}
              onItemEdit={onItemEdit}
              editImage="k-icon k-font-icon k-i-download"
              editTitle="Download"
              selectedField="Main"
              headerCellRender={headerCellRender}
              onSelectionChange={onSelectionChange}
            >
              {main && <GridColumn field="Main" width={70} editable={false} />}
              <GridColumn
                field="extension"
                title="Arquivos"
                width={85}
                cell={(props) => {
                  const cellClick = {
                    onClick: () => {
                      onRowClick(props);
                    }
                  };
                  const td = (
                    <td id="file-icon" style={{ textAlign: 'center' }} {...cellClick}>
                      {imageByType(props.dataItem.extension)}
                    </td>
                  );
                  return td;
                }}
              />
              <GridColumn field="name" title="&nbsp;" editable={false} />
            </GridInline>
          </div>
          <div className="anexo-direita">
            {showImage && url && <img src={url} alt="Arquivo" style={size} onLoad={adjustSize} />}
            {!showImage && url && (
              <object data={url} width="100%" height="100%">
                <p>Não há pré-visualização para o arquivo selecionado</p>
              </object>
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};

PopUpFiles.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpFiles;
// const requestIntervalRef = React.useRef({});
// const progressRef = React.useRef({});

// saveUrl={onSaveRequest}
// removeUrl={onRemoveRequest}
// onCancel={onCancel}

// const onSaveRequest = (_files, _, onProgress) => {
//   const uid = _files[0].uid;
//   progressRef.current[uid] = 0;

//   const saveRequestPromise = new Promise((resolve) => {
//     requestIntervalRef.current[uid] = setInterval(() => {
//       onProgress(uid, {
//         loaded: progressRef.current[uid],
//         total: 100
//       });
//       if (progressRef.current[uid] === 100) {
//         resolve({
//           uid: uid
//         });
//         delete progressRef.current[uid];
//         clearInterval(requestIntervalRef.current[uid]);
//       } else {
//         progressRef.current[uid] = progressRef.current[uid] + 1;
//       }
//     }, 1);
//   });
//   return saveRequestPromise;
// };
// const onCancel = (uid) => {
//   delete progressRef.current[uid];
//   clearInterval(requestIntervalRef.current[uid]);
// };
// const onRemoveRequest = (files, _) => {
//   const { uid } = files[0];
//   const removeRequestPromise = new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({ uid });
//     }, 300);
//   });
//   return removeRequestPromise;
// };
