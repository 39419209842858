import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
  headers: {
    Authorization: `${process.env.REACT_APP_AUTH_PREFIX} ${process.env.REACT_APP_AUTH_TOKEN}`,
  },
});

export const setApiToken = (token) => {
  axiosInstance.defaults.headers['Authorization'] = `${process.env.REACT_APP_AUTH_PREFIX} ${token}`;
}

export default axiosInstance;
