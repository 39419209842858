import React from 'react';

export const CellRender = (props) => {
  const { dataItem } = props.originalProps;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ''];
  const checkInEditField = cellField && cellField === inEditField;
  const task1 = {
    ref: (td) => {
      const input = td && td.querySelector('input');
      const { activeElement } = document;
      if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
        return;
      }
      if (input.type === 'checkbox') {
        input.focus();
      } else {
        input.select();
      }
    }
  };
  const task2 = {
    onClick: () => {
      props.enterEdit(dataItem, cellField);
    }
  };

  const additionalProps = checkInEditField ? task1 : task2;

  const clonedProps = {
    ...props.td.props,
    ...additionalProps
  };
  const childNodes = props.td.props.children;
  return React.cloneElement(props.td, clonedProps, childNodes);
};
export const RowRender = (props) => {
  const trProps = {
    ...props.tr.props,
    onBlur: () => {
      setTimeout(() => {
        const { activeElement } = document;
        if (activeElement && activeElement.className.indexOf('k-calendar') < 0) {
          props.exitEdit();
        }
      });
    }
  };
  const childNodes = props.tr.props.children;
  return React.cloneElement(
    props.tr,
    {
      ...trProps
    },
    childNodes
  );
};
