import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import FormRow from '../../components/formRow';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpError from '../../components/popUps/popUpError';
import { successNotification, warningNotification } from '../../components/notification';
import { getAll, exclude } from '../../services/taxClass';
import LoadScreen from '../load';
import './styles.css';

const TaxClasses = () => {
  const history = useHistory();
  const [taxClasses, setTaxClasses] = useState([]);
  const [selectedIds, setSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popUpConfirmVisibility, setPopUpConfirmVisibility] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const columns = [
    {
      field: 'Nome',
      title: 'Nome',
      width: '200',
      columnMenuCheckbox: true
    },
    {
      field: 'BaseCalculoISS',
      title: 'B. Cál. ISS',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'AliquotaISS',
      title: 'ISS',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'BaseCalculoPIS',
      title: 'B. Cál. PIS',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'AliquotaPIS',
      title: 'PIS',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'BaseCalculoCOFINS',
      title: 'B. Cál. COF.',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'AliquotaCOFINS',
      title: 'COFINS',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'BaseCalculoCSLL',
      title: 'B. Cál. CSLL',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'AliquotaCSLL',
      title: 'CSLL',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'BaseCalculoIRRF',
      title: 'B. Cál. IRRF',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'AliquotaIRRF',
      title: 'IRRF',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'BaseCalculoINSS',
      title: 'B. Cál. INSS',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'AliquotaINSS',
      title: 'INSS',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    }
  ];

  const loadTaxClasses = async () => {
    setLoading(true);
    const { data } = await getAll();
    setTaxClasses(data);
    setLoading(false);
  };

  const excludeTaxClasses = async () => {
    const excludeTaxClassesRequests = selectedIds.map((taxClassSelected) =>
      exclude(taxClassSelected)
    );

    const excludeTaxClassesResponses = await Promise.all(excludeTaxClassesRequests);

    const isAllRequestsRight = excludeTaxClassesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    if (isAllRequestsRight) successNotification('Classe(s) de impostos excluída(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir um ou mais classes de impostos:'];
      excludeTaxClassesResponses.forEach((element) => {
        const { data } = element;
        erro.push(data.MensagemUsuario);
      });
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }

    setSelectedsIds([]);
    await loadTaxClasses();
  };

  useEffect(() => {
    loadTaxClasses();
  }, []);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="taxClass-list">
        <h1>Classe de Impostos</h1>

        <FormRow justifyEnd withoutMarginTop space10>
          <PopUpButton
            url="/taxClasses/taxClassRegistration"
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
          />
          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (selectedIds.length > 0) setPopUpConfirmVisibility(true);
                  else warningNotification('Nenhuma classe de impostos selecionada');
                },
                text: 'Excluir Classes de Impostos'
              }
            ]}
          />
        </FormRow>
        <Table
          data={taxClasses}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => history.push(`taxClasses/editTaxClass/${Id}`)}
          sortable={true}
        />
      </div>
      <PopUpConfirm
        onConfirm={excludeTaxClasses}
        visible={popUpConfirmVisibility}
        setVisible={setPopUpConfirmVisibility}
        title="Confirmação"
        message="Você tem certeza que deseja excluir a(s) classe(s) de impostos selecionada(s)?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title="Erro ao excluir um serviço"
        message={popUpErrorMessage}
      />
    </>
  );
};

export default TaxClasses;
