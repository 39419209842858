import { Dialog } from '@progress/kendo-react-dialogs';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Error } from '@progress/kendo-react-labels';
import { OrangeButton, SimpleGrayButton, WhiteButton } from '../../buttonsComponent';
import FormRow from '../../formRow';
import { errorNotification } from '../../notification';
import './style.css';

const PopupLayout = ({
  title,
  visible,
  setVisible,
  width,
  children,
  cancelText,
  confirmText,
  onConfirm,
  className,
  haveExclude,
  excludeText,
  onDelete,
  disable,
  haveConfirm,
  onCancel,
  haveCancel,
  othersText,
  onOthers
}) => {
  const [errors, setErrors] = useState([]);
  const closePopUp = () => setVisible(false);

  const confirmAction = async () => {
    try {
      const errors = await onConfirm();
      if (errors != null && errors.length > 0) setErrors(errors);
      else {
        setErrors([]);
        closePopUp();
      }
    } catch ({ message }) {
      errorNotification(message, { autoClose: 2000 });
    }
  };

  const deleteAction = async () => {
    try {
      const errors = await onDelete();
      if (errors != null && errors.length > 0) setErrors(errors);
      else {
        setErrors([]);
        closePopUp();
      }
    } catch ({ message }) {
      errorNotification(message, { autoClose: 2000 });
    }
  };

  const cancelAction = async () => {
    if (onCancel) {
      try {
        const errors = await onCancel();
        if (errors != null && errors.length > 0) setErrors(errors);
        else {
          setErrors([]);
          closePopUp();
        }
      } catch ({ message }) {
        errorNotification(message, { autoClose: 2000 });
      }
    } else {
      setErrors([]);
      closePopUp();
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    confirmAction();
  };

  let otherButton = null;
  let haveOther = false;
  if (onOthers) {
    otherButton = onOthers.map((value, i) => (
      <OrangeButton
        key={i}
        onClick={() => {
          value();
        }}
      >
        {othersText[i]}
      </OrangeButton>
    ));
    haveOther = otherButton.length > 0;
  }

  return (
    visible && (
      <Dialog
        title={title}
        className={`popup-layout ${className}`}
        onClose={closePopUp}
        width={width}
      >
        <form onSubmit={submitForm}>
          {children}
          {errors.map((error) => (
            <Error key={error}>{error}</Error>
          ))}
          <FormRow spaceBetween alignCenter marginTop40>
            {haveExclude && (
              <SimpleGrayButton onClick={deleteAction}>{excludeText}</SimpleGrayButton>
            )}

            <FormRow justifyEnd space15 withoutMarginTop>
              {haveOther && otherButton}
              {haveConfirm && (
                <OrangeButton type="submit" disable={disable}>
                  {confirmText}
                </OrangeButton>
              )}
              {haveCancel && (
                <WhiteButton
                  onClick={() => {
                    cancelAction();
                  }}
                >
                  {cancelText}
                </WhiteButton>
              )}
            </FormRow>
          </FormRow>
        </form>
      </Dialog>
    )
  );
};

PopupLayout.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  width: PropTypes.number,
  className: PropTypes.string,
  haveExclude: PropTypes.bool,
  excludeText: PropTypes.string,
  onDelete: PropTypes.func,
  disable: PropTypes.bool,
  haveConfirm: PropTypes.bool,
  haveCancel: PropTypes.bool
};

PopupLayout.defaultProps = {
  width: 500,
  className: '',
  haveExclude: false,
  excludeText: 'Excluir',
  disable: false,
  onDelete: () => {
    throw Error('Unexpected Click');
  },
  haveConfirm: true,
  haveCancel: true
};

export default PopupLayout;
