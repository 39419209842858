import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { FormFieldGeneric } from '../../formField';
import FormRow from '../../formRow';
import Popup from '../layout';
import './styles.css';
import { AuthContext } from '../../../auth/AuthContext';
import { disable } from '../../../services/user';
import { signOut } from '../../../services/login';

const PopUpDeleteUser = ({ visible, setVisible }) => {
  const [password, setPassword] = useState('');
  const { user, setUser } = useContext(AuthContext);

  const deleteUser = async () => {
    const res = await disable({
      Email: user.Email,
      ConfirmacaoSenha: password
    });

    signOut(setUser);

    // FIXME this response isn't return error if password is wrong
    // in this case this function should be throw error
  };

  return (
    <Popup
      className="deleteUserPopup"
      title="Confirmação"
      setVisible={setVisible}
      visible={visible}
      confirmText="Ok"
      cancelText="Cancelar"
      onConfirm={deleteUser}
    >
      <span className="popUpText">
        Ao realizar esta operação, os dados do usuário e suas carteiras serão excluidos
        permanentemente. Digite sua senha abaixo para confirmar a operação, ciente de que NÃO será
        possivel recuperar os dados.
      </span>

      <FormRow>
        <FormFieldGeneric
          titleLabel="Senha"
          classNameWrapper="fill-100-field"
          onChangeValue={({ value }) => setPassword(value)}
          valueInput={password}
          type="password"
        />
      </FormRow>
    </Popup>
  );
};

PopUpDeleteUser.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpDeleteUser;
