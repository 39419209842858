/* eslint prefer-destructuring: 0 */
import React, { useState, useEffect } from 'react';
import FormFieldDrop from '../formField/drop';
import GenericField from '../formField/genericField';
import FormRow from '../formRow';
import * as personService from '../../services/person';
import LoadScreen from '../../view/load';
import './styles.css';

const Supplier = ({
  supplier,
  setSupplier,
  cpfCnpj,
  setCpfCpnj,
  address,
  setAddress,
  addresses,
  setAddresses,
  afterSupplierSelection,
  isSupplier,
  supplierName
}) => {
  const [phone, setPhone] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [loadingDiv, setLoadingDiv] = useState(false);

  const updateSupplierDetails = async (supplier, address) => {
    let newAddress = address;
    if (supplier != null) {
      setCpfCpnj('');
      setPhone('');
      setCellPhone('');
      setAddress(null);
      if (supplier.TipoPessoa === 0) {
        setCpfCpnj(supplier.CPF);
        setAddress(supplier.Endereco);
        newAddress = supplier.Endereco;
        setPhone(supplier.Telefone?.Numero);
        setCellPhone(supplier.Celular?.Numero);
      } else {
        let addresses = [];
        if (supplier.EnderecoPrincipal != null) addresses.push(supplier.EnderecoPrincipal);
        addresses = addresses.concat(supplier.OutrosEnderecos);
        addresses = addresses.map((item) => ({
          ...item,
          EnderecoCompleto: [item.Logradouro, item.Numero, item.Complemento, item.Bairro]
            .filter(Boolean)
            .join(', ')
        }));
        if (newAddress != null) {
          const telefone = newAddress.Telefones?.find((x) => x.Descricao === 'Telefone');
          const celular = newAddress.Telefones?.find((x) => x.Descricao === 'Celular');
          setPhone(telefone?.Numero);
          setCellPhone(celular?.Numero);
          setCpfCpnj(newAddress.CNPJ ? newAddress.CNPJ : '');
          setAddress(newAddress);
        } else if (addresses.length === 1) {
          const telefone = addresses[0].Telefones?.find((x) => x.Descricao === 'Telefone');
          const celular = addresses[0].Telefones?.find((x) => x.Descricao === 'Celular');
          setPhone(telefone?.Numero);
          setCellPhone(celular?.Numero);
          setCpfCpnj(addresses[0].CNPJ ? addresses[0].CNPJ : '');
          setAddress(addresses[0]);
          newAddress = addresses[0];
        }
        setAddresses(addresses);
      }
      return newAddress;
    }
    setCpfCpnj('');
    setPhone('');
    setCellPhone('');
    setAddress(null);
    setAddresses([]);
    return null;
  };

  const loadSupplier = async (supplier) => {
    setLoadingDiv(true);
    setSupplier(supplier);
    const addressContact = await updateSupplierDetails(supplier, null);
    if (afterSupplierSelection) afterSupplierSelection(supplier, addressContact.address);
    setLoadingDiv(false);
  };

  const loadSuppliers = async (filter) => {
    const { data } = await personService.getBySupplierName(filter);
    return data;
  };

  const loadCollaborators = async (filter) => {
    const { data } = await personService.getByCollaboratorName(filter);
    return data;
  };

  useEffect(async () => {
    await updateSupplierDetails(supplier, address);
  }, []);

  return (
    <div>
      {loadingDiv ? (
        <LoadScreen />
      ) : (
        <>
          <FormRow withShrink>
            <FormFieldDrop
              titleLabel={supplierName}
              defaultValueSelect={`Procure o ${supplierName}...`}
              onChangeValue={async (val) => {
                if (val.value) {
                  await loadSupplier(val.value);
                } else {
                  setSupplier(null);
                  updateSupplierDetails(null);
                  if (afterSupplierSelection) afterSupplierSelection(null, null, null);
                }
              }}
              value={supplier}
              className="fill-33-field-margin"
              loadData={isSupplier ? loadSuppliers : loadCollaborators}
              serverFiltering={true}
              dataItemKey="Id"
              textField="Nome"
              required
            />
            {supplier != null && supplier.TipoPessoa !== 0 && (
              <FormFieldDrop
                titleLabel="Matriz/Filial"
                defaultValueSelect="Selecione..."
                onChangeValue={(val) => {
                  if (val.value) {
                    updateSupplierDetails(supplier, val.value);
                    if (afterSupplierSelection) afterSupplierSelection(supplier, val.value, null);
                  }
                }}
                value={address}
                className="fill-33-field-margin"
                infosSelect={addresses}
                dataItemKey="Id"
                textField="EnderecoCompleto"
              />
            )}
            <GenericField
              titleLabel="CPF/CNPJ"
              value={cpfCnpj}
              classNameWrapper="fill-33-field-margin"
              disabled
            />
          </FormRow>
          <FormRow>
            <div className="fill-33-field-margin">
              <FormRow withoutMarginTop>
                <GenericField
                  titleLabel="Rua/Avenida"
                  value={address?.Logradouro}
                  classNameWrapper="fill-70-field"
                  disabled
                />
                <GenericField
                  titleLabel="Numero"
                  value={address?.Numero}
                  classNameWrapper="fill-30-field"
                  disabled
                />
              </FormRow>
            </div>
            <div className="fill-33-field-margin">
              <FormRow withoutMarginTop>
                <GenericField
                  titleLabel="Complemento"
                  value={address?.Complemento}
                  classNameWrapper="fill-30-field"
                  disabled
                />
                <GenericField
                  titleLabel="Bairro"
                  value={address?.Bairro}
                  classNameWrapper="fill-70-field"
                  disabled
                />
              </FormRow>
            </div>
            <div className="fill-33-field-margin">
              <FormRow withoutMarginTop>
                <GenericField
                  titleLabel="Cidade"
                  value={address?.Municipio?.nomeMunicipio}
                  classNameWrapper="fill-70-field"
                  disabled
                />
                <GenericField
                  titleLabel="UF"
                  value={address?.Municipio?.siglaEstado}
                  classNameWrapper="fill-30-field"
                  disabled
                />
              </FormRow>
            </div>
          </FormRow>
          <FormRow>
            <GenericField
              titleLabel="Telefone"
              value={phone}
              classNameWrapper="fill-33-field-margin"
              disabled
            />
            <GenericField
              titleLabel="Celular"
              value={cellPhone}
              classNameWrapper="fill-33-field-margin"
              disabled
            />
            <GenericField
              titleLabel="E-mail"
              value={supplier?.Email}
              classNameWrapper="fill-33-field-margin"
              disabled
            />
          </FormRow>
        </>
      )}
    </div>
  );
};

export default Supplier;
