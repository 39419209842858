import React from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import Label from '../components/label';
import './style.css';

const FormFieldCheckbox = ({ title, classNameWrapper, ...other }) => (
  <div className={`wrapper-field ${classNameWrapper}`}>
    <Checkbox {...other} />
  </div>
);

export default FormFieldCheckbox;
