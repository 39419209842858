import PropTypes from 'prop-types';
import * as React from 'react';
import MonthCalendar from '../monthCalendar';
import './styles.css';

const CalendarWithButtons = ({ date, setDate, service }) => {
  const retreatMonth = () => {
    const newDate = new Date(date.setMonth(date.getMonth() - 1));

    setDate(newDate);
  };

  const advanceMonth = () => {
    const newDate = new Date(date.setMonth(date.getMonth() + 1));

    setDate(newDate);
  };

  const lastDate = async () => {
    const newDate = await service.getLast();
    if (newDate !== null) setDate(newDate);
    else setDate(date);
  };

  const firstDate = async () => {
    const newDate = await service.getFirst();
    if (newDate !== null) setDate(newDate);
    else setDate(date);
  };

  return (
    <div className="calendar-invoice">
      <input className="previus-more-icon" type="button" alt="Voltar um ano" onClick={firstDate} />
      <input className="previus-icon" type="button" alt="Voltar um mês" onClick={retreatMonth} />
      <MonthCalendar date={date} setDate={setDate} />
      <input className="advance-icon" type="button" alt="Avançar um mês" onClick={advanceMonth} />
      <input className="advance-more-icon" type="button" alt="Avançar um ano" onClick={lastDate} />
    </div>
  );
};

CalendarWithButtons.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired
};

export default CalendarWithButtons;
