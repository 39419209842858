import { useState } from 'react';
import Popup from '../layout';
import FormRow from '../../formRow';
import RadioGroup from '../../formField/radioGroup';

const PopUpSettings = ({ onConfirm, visible, setVisible, title, filter }) => {
  const [_filter, _setFilter] = useState(filter);
  const confirmSetting = () => {
    onConfirm(_filter);
  };

  return (
    <Popup
      title={title}
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText="Salvar"
      cancelText="Cancelar"
      onConfirm={confirmSetting}
    >
      <span style={{ marginTop: '10px' }}>Visualização dos dados</span>
      <FormRow withShrink>
        <RadioGroup
          elements={[
            {
              id: 'day',
              value: 'day',
              label: 'Por período',
              checked: _filter === 'day'
            },
            {
              id: 'month',
              value: 'month',
              label: 'Mensal',
              checked: _filter === 'month'
            }
          ]}
          group="filter"
          setValue={_setFilter}
        />
      </FormRow>
    </Popup>
  );
};

export default PopUpSettings;
