import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
  CardImage,
  CardFooter,
  Avatar
} from '@progress/kendo-react-layout';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import * as documentService from '../../../services/document';
import LoadScreen from '../../load';
import NFSe from '../../../assets/icone-nfse.png';
import Recibo from '../../../assets/icone-recibo.png';
import PopUpButton, { INTERNAL_BUTTON } from '../../../components/button';
import './styles.css';

const Documents = () => {
  const history = useHistory();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadDocuments = async () => {
    setLoading(true);
    const { data } = await documentService.getAll();
    setDocuments(data);
    setLoading(false);
  };

  const CardContainer = () => {
    const htmlList = [];
    documents.forEach((item) => {
      const coin = item.MoedaNome ? item.MoedaNome : 'R$';
      const html = (
        <Card orientation="horizontal" layout="end">
          {item.TipoDocumentoId !== 2 ? <CardImage src={NFSe} /> : <CardImage src={Recibo} />}
          <div className="k-vbox">
            <CardBody>
              <CardTitle>{item.Nome}</CardTitle>
            </CardBody>
            <CardActions
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <button
                  type="button"
                  style={{ paddingLeft: '16px', margin: '0px', minWidth: '0' }}
                  className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                  onClick={() => history.push(`documents/editDocument/${item.Id}`)}
                >
                  <span className="k-icon k-font-icon k-i-edit" style={{ color: '#00A6D2' }} />
                </button>
              </div>
              <Avatar type="text" size="large" shape="circle" style={{ background: '#00A6D2' }}>
                {coin}
              </Avatar>
            </CardActions>
          </div>
        </Card>
      );
      htmlList.push(html);
    });
    const html = (
      <Card orientation="horizontal" layout="end">
        <div className="k-vbox">
          <CardBody style={{ textAlign: 'center', padding: '16px 0px 0px 16px' }}>
            {/* <Avatar type="text" size="medium" shape="circle" style={{ background: '#00A6D2' }}>
              <button
                type="button"
                className="k-button k-button-md k-rounded-md k-button-flat"
                onClick={() => {
                  history.push(`/settings/documents/documentRegistration/`);
                }}
              >
                <span className="k-icon k-font-icon k-i-plus" />
              </button>
            </Avatar> */}
            <div className="config-components-popup-button">
              <FloatingActionButton
                icon={INTERNAL_BUTTON.BUTTON_ADD}
                onClick={() => {
                  history.push(`/settings/documents/documentRegistration/`);
                }}
              />
            </div>
          </CardBody>
          <CardFooter style={{ textAlign: 'center' }}>Adicionar novo documento</CardFooter>
        </div>
      </Card>
    );
    htmlList.push(html);
    return htmlList;
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="document-list">
        <h1>Documentos</h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            flexWrap: 'wrap',
            marginTop: '10px',
            columnGap: '10px',
            rowGap: '10px'
          }}
        >
          <CardContainer />
        </div>
      </div>
    </>
  );
};

export default Documents;
