import PropTypes from 'prop-types';
import { useState } from 'react';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import FormRow from '../../formRow';
import Popup from '../layout';

const PopUpAddServProd = ({ addService, visible, setVisible }) => {
  const [servProd, setServProd] = useState(null);
  const [selectValue, setSelectValue] = useState(0);
  const [amount, setAmount] = useState(1);
  const [unit, setUnit] = useState(null);

  const confirmAddService = () => {
    if (amount <= 0) {
      throw Error('O campo quantidade deve ser maior 0.');
    } else {
      const item = {
        DescricaoServProd: servProd,
        Quantidade: amount,
        UnidadeMedida: unit,
        Desconto: 0,
        Valor: selectValue,
        ValorTotal: amount * selectValue
      };
      addService(item);
      setServProd(null);
      setSelectValue(0);
      setAmount(1);
    }
  };

  return (
    <Popup
      title="Adicionar Produto ou Serviço"
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText="Adicionar"
      cancelText="Cancelar"
      onConfirm={confirmAddService}
    >
      <FormRow>
        <GenericField
          titleLabel="Nome do Produto ou Serviço"
          classNameWrapper="fill-100-field"
          valueInput={servProd}
          onChangeValue={(val) => {
            setServProd(val.value);
          }}
          required
        />
      </FormRow>
      <FormRow justifyEnd>
        <GenericField
          titleLabel="Quantidade"
          classNameWrapper="fill-100-field"
          valueInput={amount}
          onChangeValue={(e) => {
            const {
              target: { name, valueAsNumber }
            } = e;
            setAmount(valueAsNumber);
          }}
          name="amount"
          required
          typeNumber
          min="0"
        />
      </FormRow>
      <FormRow>
        <GenericField
          titleLabel="Unidade de Medida"
          classNameWrapper="fill-100-field"
          valueInput={unit}
          onChangeValue={(val) => {
            setUnit(val.value);
          }}
        />
      </FormRow>
      <FormRow justifyEnd>
        <GenericField
          titleLabel="Valor"
          enableReais
          classNameWrapper="fill-100-field"
          valueInput={selectValue}
          setValue={setSelectValue}
          iconEnabled={INTERNAL_ICON.REAIS_ICON}
          required
          min="0"
        />
      </FormRow>
    </Popup>
  );
};

PopUpAddServProd.propTypes = {
  addService: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpAddServProd.defaultProps = {};

export default PopUpAddServProd;
