import pt from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomInput from '../CustomInput';
import './styles.css';

const MonthCalendar = ({ date, setDate, wrapperClassName, ...other }) => {
  registerLocale('pt', pt);

  return (
    <DatePicker
      selected={date}
      onChange={setDate}
      locale="pt"
      wrapperClassName={`month-calendar-field ${wrapperClassName}`}
      customInput={<CustomInput />}
      popperClassName="month-calendar"
      dateFormat="MMMM yyyy"
      showMonthYearPicker
      showFourColumnMonthYearPicker
      {...other}
    />
  );
};

MonthCalendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string
};

MonthCalendar.defaultProps = {
  wrapperClassName: ''
};

export default MonthCalendar;
