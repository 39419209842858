import PropTypes from 'prop-types';
import React from 'react';
import { getFinalValues } from '../../../utils/object';
import Label from '../components/label';
import InputField, { ICON } from './input';

export const INTERNAL_ICON = ICON;
const GenericField = ({
  titleLabel,
  classNameWrapper,
  classNameInput,
  onChangeValue,
  disabled,
  enableInfo,
  isTextArea,
  msg,
  typeNumber,
  enablePlusIcon,
  onClickPlusIcon,
  valueInput,
  iconEnabled,
  required,
  mask,
  ...others
}) => (
  <>
    <div className={`wrapper-field ${classNameWrapper}`}>
      <Label enableIcon={enablePlusIcon} onClickIcon={onClickPlusIcon}>
        {required ? `${titleLabel}*` : `${titleLabel}`}
      </Label>
      <InputField
        classNameInput={classNameInput}
        onChangeValue={onChangeValue}
        disabled={disabled}
        enableInfo={enableInfo}
        isTextArea={isTextArea}
        msg={msg}
        typeNumber={typeNumber}
        valueInput={valueInput}
        iconEnabled={iconEnabled}
        required={required}
        mask={mask}
        {...others}
      />
    </div>
  </>
);

GenericField.propTypes = {
  titleLabel: PropTypes.string,
  onChangeValue: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  classNameWrapper: PropTypes.string,
  classNameInput: PropTypes.string,
  iconEnabled: PropTypes.oneOf(getFinalValues(ICON)),
  isTextArea: PropTypes.bool,
  msg: PropTypes.string,
  typeNumber: PropTypes.bool,
  enableInfo: PropTypes.bool,
  onClickPlusIcon: PropTypes.func,
  valueInput: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  enablePlusIcon: PropTypes.bool,
  required: PropTypes.bool,
  mask: PropTypes.string
};

GenericField.defaultProps = {
  onChangeValue: undefined,
  disabled: false,
  enableInfo: false,
  isTextArea: false,
  icon: undefined,
  classNameWrapper: '',
  classNameInput: '',
  typeNumber: false,
  msg: '',
  titleLabel: '',
  iconEnabled: null,
  onClickPlusIcon: () => {
    throw Error('Unexpected click');
  },
  valueInput: '',
  enablePlusIcon: false,
  required: false,
  mask: null
};

export default GenericField;
