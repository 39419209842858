import PropTypes from 'prop-types';
import { useState } from 'react';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import FormFieldDrop from '../../formField/drop';
import FormRow from '../../formRow';
import Popup from '../layout';

const PopUpAddTransport = ({ addTransport, visible, setVisible, transportTypes }) => {
  const [transportType, setTransportType] = useState(null);
  const [name, setName] = useState(null);
  const [amount, setAmount] = useState(null);
  const [weight, setWeight] = useState(null);
  const [selectValue, setSelectValue] = useState(0);

  const confirmAddTransport = () => {
    const item = {
      Nome: name,
      TipoFrete: transportType,
      Volume: amount,
      Peso: weight,
      Valor: selectValue
    };
    addTransport(item);
    setTransportType(null);
    setName(null);
    setAmount(null);
    setWeight(null);
    setSelectValue(0);
  };

  return (
    <Popup
      title="Adicionar Frete"
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText="Adicionar"
      cancelText="Cancelar"
      onConfirm={confirmAddTransport}
    >
      <FormRow>
        <GenericField
          titleLabel="Empresa"
          classNameWrapper="fill-100-field"
          valueInput={name}
          onChangeValue={(val) => {
            setName(val.value);
          }}
        />
      </FormRow>
      <FormRow>
        <FormFieldDrop
          titleLabel="Tipo de Frete"
          defaultValueSelect="Selecione..."
          infosSelect={transportTypes}
          onChangeValue={(val) => {
            setTransportType(val.value);
          }}
          className="fill-100-field"
          value={transportType}
          dataItemKey="Id"
          textField="Nome"
        />
      </FormRow>
      <FormRow justifyEnd>
        <GenericField
          titleLabel="Volume"
          classNameWrapper="fill-100-field"
          valueInput={amount}
          onChangeValue={(e) => {
            const {
              target: { name, valueAsNumber }
            } = e;
            setAmount(valueAsNumber);
          }}
          name="amount"
          typeNumber
          min="0"
        />
      </FormRow>
      <FormRow justifyEnd>
        <GenericField
          titleLabel="Peso"
          classNameWrapper="fill-100-field"
          valueInput={weight}
          onChangeValue={(e) => {
            const {
              target: { name, valueAsNumber }
            } = e;
            setWeight(valueAsNumber);
          }}
          name="weight"
          typeNumber
          min="0"
        />
      </FormRow>
      <FormRow justifyEnd>
        <GenericField
          titleLabel="Valor"
          enableReais
          classNameWrapper="fill-100-field"
          valueInput={selectValue}
          setValue={setSelectValue}
          iconEnabled={INTERNAL_ICON.REAIS_ICON}
          required
          min="0"
        />
      </FormRow>
    </Popup>
  );
};

PopUpAddTransport.propTypes = {
  addTransport: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpAddTransport.defaultProps = {};

export default PopUpAddTransport;
