import { useState, useEffect } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import LabelField from '../../formField/label';
import GridInline from '../../grid';
import DropDownCell from '../../grid/dropDownCell';
import { OrangeButton } from '../../buttonsComponent';
import FormRow from '../../formRow';
import Popup from '../layout';
import { hasDuplicate } from '../../../utils/arrays';
import * as cardService from '../../../services/card';
import { successNotification, errorNotification, warningNotification } from '../../notification';
import NumberCell from '../../grid/numberCell';

const PopUpCardParcel = ({
  addCardParcel,
  visible,
  setVisible,
  cardTax,
  cardParcels,
  setCardParcels
}) => {
  const [parcels, setParcels] = useState([]);

  const loadParcels = async () => {
    let { data } = await cardService.getParcels();
    data = data.map((item) => ({
      Id: parseInt(item.Id, 10),
      Nome: item.Descricao
    }));
    setParcels(data);
    return data;
  };

  const addInternalParcel = () => {
    const id = cardParcels.length > 0 ? cardParcels[cardParcels.length - 1].Id + 1 : 1;
    const newParcels = {
      Id: id,
      ParcelaInicio: null,
      ParcelaFim: null,
      Taxa: 0
    };
    setCardParcels([...cardParcels, newParcels]);
  };

  const confirmCardParcel = async () => {
    cardParcels.forEach((item) => {
      if (!item.ParcelaInicio) {
        throw Error('Existem parcelas sem definição do mês inicial.');
      }
      if (!item.ParcelaFim) {
        throw Error('Existem parcelas sem definição do mês final.');
      }
      if (item.ParcelaInicio.Id >= item.ParcelaFim.Id) {
        throw Error('Existem parcelas com o mês inicial maior ou igual ao mês final.');
      }
      if (item.ParcelaInicio.Id >= item.ParcelaFim.Id) {
        throw Error('Existem parcelas com o mês inicial maior ou igual ao mês final.');
      }
      if (item.Taxa <= 0) {
        throw Error('O campo taxa deve ser maior 0.');
      }
    });
    for (let i = 0; i < cardParcels.length - 1; i++) {
      if (cardParcels[i].ParcelaFim?.Id >= cardParcels[i + 1].ParcelaInicio?.Id)
        throw Error('Existem parcelas com interseção de meses');
    }
    if (hasDuplicate(cardParcels, 'ParcelaInicio', 'Id')) {
      throw Error('Existem parcelas com a mesma referência inicial de mês.');
    }
    if (hasDuplicate(cardParcels, 'ParcelaFim', 'Id')) {
      throw Error('Existem parcelas com a mesma referência final de mês.');
    }
    const newCardParcels = cardParcels.map((element) => ({
      ...element,
      ParcelaInicio: element.ParcelaInicio?.Id,
      ParcelaFim: element.ParcelaFim?.Id
    }));
    const response = await cardService.updateParcels(cardTax.Id, newCardParcels);
    if (response.status === 200) {
      successNotification('Parcelas criadas/atualizadas com sucesso!');
      addCardParcel();
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar criar/atualizar as parcelas de cartão.');
    }
  };

  useEffect(async () => {
    const parcels = await loadParcels();
  }, []);

  return (
    <Popup
      title="Parcelamento de Cartão de Crédito"
      width={600}
      visible={visible}
      setVisible={setVisible}
      confirmText="Salvar"
      cancelText="Cancelar"
      onConfirm={confirmCardParcel}
    >
      <FormRow>
        <LabelField
          titleLabel="Est./Operadora/Bandeira"
          classNameWrapper="fill-50-field"
          valueLabel={cardTax?.Operadora}
        />
        <GenericField
          titleLabel="Dias p/ recebimento"
          classNameWrapper="fill-50-field"
          valueInput={cardTax?.DiaCredito}
          typeNumber
          disabled
        />
      </FormRow>
      <FormRow withShrink>
        <GridInline data={cardParcels} setData={setCardParcels} deleteColumn={true}>
          <GridColumn
            field="ParcelaInicio"
            title="De"
            editable={true}
            cell={(props) => DropDownCell(props, parcels, 'Id', 'Nome')}
          />
          <GridColumn
            field="ParcelaFim"
            title="Até (Inclusive)"
            editable={true}
            cell={(props) => DropDownCell(props, parcels, 'Id', 'Nome')}
          />
          <GridColumn
            field="Taxa"
            title="Taxa"
            editor="numeric"
            cell={NumberCell}
            editable={true}
          />
        </GridInline>
      </FormRow>
      <FormRow justifyEnd space10>
        <OrangeButton onClick={async () => addInternalParcel()}>Adicionar</OrangeButton>
      </FormRow>
    </Popup>
  );
};

export default PopUpCardParcel;
