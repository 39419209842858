import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

const ToggleInvoice = ({ title, children, startExpanded, conteinerClassName }) => (
  <div className="toggle-invoice">
    <PanelBar className="panel-bar-toggle">
      <PanelBarItem title={title} expanded={startExpanded}>
        <div id="line" />
        <div className={conteinerClassName}>{children}</div>
      </PanelBarItem>
    </PanelBar>
  </div>
);

ToggleInvoice.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  conteinerClassName: PropTypes.string,
  startExpanded: PropTypes.bool
};

ToggleInvoice.defaultProps = {
  startExpanded: false,
  conteinerClassName: ''
};

export default ToggleInvoice;
