import * as React from 'react';
import DropDown from '../drop';

const DropDownCell = (props, elements, valueField, textField, disabled) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  const isInEdit = field === dataItem.inEdit;
  const value = field && dataItem[field] ? dataItem[field] : { Id: '', Descricao: '' };
  const onChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
        disabled
      });
    }
  };

  const description = value ? value[textField] : '';
  const defaultRendering = (
    <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
      {isInEdit ? (
        <DropDown
          className="fill-100-field"
          infosSelect={elements}
          dataItemKey={valueField}
          textField={textField}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      ) : (
        description
      )}
    </td>
  );
  return render?.call(undefined, defaultRendering, props);
};

export default DropDownCell;
