import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import * as contractService from '../../../services/contract';
import Popup from '../layout';
import FormFieldDrop from '../../formField/drop';
import FormRow from '../../formRow';

const PopUpSelectContractType = ({ onConfirm, visible, setVisible, title, message }) => {
  const [contractTypes, setContractTypes] = useState([]);
  const [contract, setContractType] = useState(null);
  const _message = message != null ? message : 'Selecione o tipo de documento?';

  const confirmContractType = () => {
    onConfirm(contract.Id);
  };

  const loadContractTypes = async () => {
    const { data } = await contractService.getContractTypes();
    setContractTypes(data);
    setContractType(data[0]);
    return data;
  };

  useEffect(() => {
    loadContractTypes();
  }, []);

  return (
    <Popup
      title={title}
      width={700}
      visible={visible}
      setVisible={setVisible}
      confirmText="Sim"
      cancelText="Não"
      onConfirm={confirmContractType}
    >
      <span style={{ marginTop: '10px' }}>{_message}</span>
      <FormRow withShrink>
        <FormFieldDrop
          titleLabel="Tipo de Contrato"
          defaultValueSelect="Selecione..."
          onChangeValue={(val) => {
            setContractType(val.value);
          }}
          value={contract}
          className="fill-50-field"
          infosSelect={contractTypes}
          dataItemKey="Id"
          textField="Nome"
          required
        />
      </FormRow>
    </Popup>
  );
};

PopUpSelectContractType.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpSelectContractType;
