import { getter } from '@progress/kendo-react-common';
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import caGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/pt/dateFields.json';
import numbers from 'cldr-numbers-full/main/pt/numbers.json';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { WhiteButton } from '../buttonsComponent';
import ElipseEmpty from '../../assets/elipse-empty.svg';
import ElipseFill from '../../assets/elipse-fill.svg';
import { toMoney } from '../../utils/formatter';
import ptMessages from '../../utils/pt.json';
import { CheckValidDate } from '../../utils/date';
import './style.css';
import { ColumnMenu, ColumnMenuCheckbox } from './columnMenu';
import { TotalCell, CountCell } from '../grid/totalCell';

load(likelySubtags, weekData, caGregorian, dateFields, numbers);
loadMessages(ptMessages, 'pt');

const SELECTED_FIELD = 'selected';

const Table = ({
  name,
  columns,
  data,
  dataItemKey,
  haveCheckbox,
  style,
  setItemKeysSelected,
  onClickInRow,
  sortable,
  filterable,
  rowIndex,
  haveOnClick,
  filterValue,
  sortValue
}) => {
  const idGetter = getter(dataItemKey);
  const { width } = style;
  const setPercentage = (percentage) => Math.round(width / 100) * percentage;
  const [dataTable, setDataTable] = useState([]);
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = filterValue ? useState([filterValue]) : useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [_columns, setColumns] = useState(columns);
  const [_filterable, setFilterable] = useState(filterable);
  const [hasFilter, setHasFilter] = useState(false);

  const objsBooleansToIds = (obj) => {
    const ids = [];
    Object.keys(obj).forEach((key) => {
      if (obj[key]) ids.push(key);
    });
    return ids;
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = selectedState;
      if (newSelectedState[event.dataItem.Id] === undefined) {
        newSelectedState[event.dataItem.Id] = true;
      } else {
        newSelectedState[event.dataItem.Id] = !newSelectedState[event.dataItem.Id];
      }
      setItemKeysSelected(objsBooleansToIds(newSelectedState));
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {
    const { checked } = event.syntheticEvent.target;
    const { data } = event.target.props;
    const newSelectedState = {};

    data.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });

    setItemKeysSelected(objsBooleansToIds(newSelectedState));
    setSelectedState(newSelectedState);
  }, []);

  const defaultCell = ({ dataItem, field }, value) => {
    const valueToShow = value.subField ? dataItem[field][value.subField] : dataItem[field];

    let cellClickHandlerProps = {
      onClick: () => onClickInRow(dataItem, field)
    };
    cellClickHandlerProps = haveOnClick ? cellClickHandlerProps : '';
    let className = '';
    if (value.isCash) className = 'cash-column';
    else if (value.isCenter) className = 'center-column';
    else if (value.isRight) className = 'right-column';
    return value.isCash ? (
      <td {...cellClickHandlerProps} className={className}>
        {toMoney(dataItem[field], true)}
      </td>
    ) : (
      <td {...cellClickHandlerProps} className={className}>
        {valueToShow instanceof Date ? valueToShow.toLocaleDateString('pt-BR') : valueToShow}
      </td>
    );
  };

  const changeGridProperty = (gridRef) => {
    if (gridRef && Object.keys(selectedState).length === 0 && rowIndex) {
      const { length } = filterBy(dataTable, filter);
      if (length > 0 && rowIndex > length) gridRef.scrollIntoView({ rowIndex: length });
      else gridRef.scrollIntoView({ rowIndex });
    }
  };

  const saveStateToLocalStorage = (filter, sort, columns, filterable) => {
    const state = { filter, sort, columns, filterable };
    localStorage.setItem(`gridState${name}`, JSON.stringify(state));
  };

  const convertFilter = (filters) => {
    if (filters) {
      const newFilters = filters.map((item) => {
        const date = new Date(item.value);
        if (CheckValidDate(date))
          return {
            ...item,
            value: date
          };
        return item;
      });
      return newFilters;
    }
    return filters;
  };

  const loadStateFromLocalStorage = () => {
    const savedState = localStorage.getItem(`gridState${name}`);
    if (savedState) {
      const {
        filter: savedFilter,
        sort: savedSort,
        columns: savedColumns,
        filterable: savedFilterable
      } = JSON.parse(savedState);

      if (savedFilter?.filters) {
        savedFilter.filters = savedFilter.filters.map((item) => ({
          ...item,
          filters: convertFilter(item.filters)
        }));
      }

      setFilter(savedFilter);
      if (savedFilter) setHasFilter(true);
      else setHasFilter(false);
      setSort(savedSort);
      if (savedColumns) {
        const newColumns = _columns.map((item) => {
          const column = savedColumns.find((item2) => item2.field === item.field);
          if (column)
            return {
              ...item,
              width: column.width
            };
          return item;
        });
        setColumns(newColumns);
      }
      setFilterable(savedFilterable);
    }
  };

  const onFilterChange = (event) => {
    setFilter(event.filter);
    if (event.filter) setHasFilter(true);
    else setHasFilter(false);
    saveStateToLocalStorage(event.filter, sort, _columns, _filterable);
  };

  const onSortChange = (event) => {
    setSort(event.sort);
    saveStateToLocalStorage(filter, event.sort, _columns, _filterable);
  };

  const onColumnResize = (event) => {
    saveStateToLocalStorage(filter, sort, event.columns, _filterable);
  };

  const clearFilters = () => {
    setFilter(null);
    setHasFilter(false);
    saveStateToLocalStorage(null, sort, _columns, _filterable);
  };

  useEffect(() => {
    const newDataTable = data.map((dataItem) => ({
      [SELECTED_FIELD]: false,
      ...dataItem
    }));
    setDataTable(newDataTable);
    const dataKeys = data.map((row) => row[dataItemKey]);
    const newSelectedState = {};
    Object.entries(selectedState).forEach(([key, isSelected]) => {
      if (dataKeys.includes(key)) newSelectedState[key] = isSelected;
    });
    setSelectedState(newSelectedState);
    loadStateFromLocalStorage();
  }, [data]);

  return (
    <LocalizationProvider language="pt">
      <IntlProvider locale="pt">
        <Grid
          data={filterBy(
            orderBy(
              dataTable.map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)]
              })),
              sort
            ),
            filter
          )}
          dataItemKey={dataItemKey}
          selectedField={SELECTED_FIELD}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          style={style}
          sortable={sortable}
          sort={sort}
          onSortChange={onSortChange}
          onFilterChange={onFilterChange}
          className="table"
          resizable={true}
          ref={changeGridProperty}
          onColumnResize={onColumnResize}
        >
          {hasFilter && (
            <GridToolbar>
              <WhiteButton onClick={clearFilters}>Limpar Filtro</WhiteButton>
            </GridToolbar>
          )}
          <GridNoRecords>Sem registros</GridNoRecords>
          {haveCheckbox && (
            <Column
              field={SELECTED_FIELD}
              filterable={false}
              width={50}
              headerSelectionValue={
                dataTable.findIndex((item) => !selectedState[idGetter(item)]) === -1
              }
            />
          )}
          {_columns.map((value, index) => {
            if (value.isRadio) {
              return (
                <Column
                  field={value.field}
                  title={value.title}
                  format={value.format}
                  width={value.isPercentage ? setPercentage(value.width) : value.width}
                  headerClassName={value.isRadio && 'radio-button'}
                  cell={({ dataItem, field }) => (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <td className="radio-button" onClick={() => onClickInRow(dataItem)} onKeyPress>
                      {dataItem[field] ? (
                        <img src={ElipseFill} alt="Item preenchido" />
                      ) : (
                        <img src={ElipseEmpty} alt="Item vazio" />
                      )}
                    </td>
                  )}
                  key={`${value.field.toString()}_${index}`}
                  footerCell={value.footerCell}
                />
              );
            }
            let headerClassName = '';
            if (value.isCash && !value.columnMenu && !value.columnMenuCheckbox)
              headerClassName = 'cash-column';
            else if (value.isCenter && !value.columnMenu && !value.columnMenuCheckbox)
              headerClassName = 'center-column';
            else if (value.isRight && !value.columnMenu && !value.columnMenuCheckbox)
              headerClassName = 'right-column';
            let footerCell = null;
            if (value.totalCell)
              footerCell = (props) => TotalCell(props, filterBy(dataTable, filter), value.field);
            if (value.countCell)
              footerCell = (props) => CountCell(props, filterBy(dataTable, filter));
            let columnMenu;
            if (value.columnMenu) columnMenu = ColumnMenu;
            if (value.columnMenuCheckbox) {
              columnMenu = (props) => <ColumnMenuCheckbox {...props} data={dataTable} />;
            }
            return (
              <Column
                field={value.field}
                title={value.title}
                filter={value.filter}
                format={value.format}
                width={value.isPercentage ? setPercentage(value.width) : value.width}
                headerClassName={headerClassName}
                cell={value.cell === undefined ? (props) => defaultCell(props, value) : value.cell}
                key={`${value.field.toString()}_${index}`}
                footerCell={footerCell}
                columnMenu={columnMenu}
              />
            );
          })}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      subField: PropTypes.string,
      title: PropTypes.string,
      width: PropTypes.number,
      isRadio: PropTypes.bool,
      isPercentage: PropTypes.bool,
      isCash: PropTypes.bool
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
  dataItemKey: PropTypes.string.isRequired,
  style: PropTypes.shape({ subProp: PropTypes.string }),
  haveCheckbox: PropTypes.bool,
  setItemKeysSelected: PropTypes.func,
  onClickInRow: PropTypes.func,
  sortable: PropTypes.bool,
  filterable: PropTypes.bool,
  haveOnClick: PropTypes.bool
};

Table.defaultProps = {
  haveCheckbox: false,
  style: {},
  setItemKeysSelected: () => {
    throw new Error('Not Implemented');
  },
  sortable: false,
  filterable: false,
  haveOnClick: true
};

export default Table;
