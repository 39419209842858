import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { useInternationalization, load } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import brNumbers from 'cldr-numbers-full/main/br/numbers.json';
import brCurrency from 'cldr-numbers-full/main/br/currencies.json';
import caGregorian from 'cldr-dates-full/main/br/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/br/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/br/timeZoneNames.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import { INTERNAL_BUTTON } from '../../button';
import { CellRender, RowRender } from '../cellRender';
import { SmallOrangeButton } from '../../buttonsComponent';
import DeleteIcon from '../../../assets/icone-excluir.svg';
import DivideIcon from '../../../assets/icone-divisao.svg';
import './style.css';

load(brNumbers, brCurrency, currencyData, likelySubtags, caGregorian, dateFields, timeZoneNames);

const EDIT_FIELD = 'inEdit';

const GridRowSpan = ({
  data,
  setData,
  children,
  onItemChange,
  commandColumn,
  onItemCommand,
  popUpCommandColumn,
  rowSpanField,
  ...other
}) => {
  const intl = useInternationalization();
  intl.locale = 'br';
  const [changes, setChanges] = useState(false);

  const remove = (dataItem) => {
    const index = data.findIndex((item) => item.Descricao === dataItem.Descricao);
    const items = data.filter((item) => item.Descricao === dataItem.Descricao);
    const sum = items.reduce((partialSum, item) => partialSum + item.Valor, 0);
    data.splice(index, items.length, {
      Id: dataItem.Id,
      Descricao: dataItem.Descricao,
      PlanoDeContas: null,
      CentroDeCustos: null,
      Valor: sum
    });
    setData([...data]);
  };

  const command = (dataItem) => {
    const index = data.findIndex((record) => record.Id === dataItem.Id);
    data.splice(index, 1);
    const newData = [...data];
    setData(newData);
  };

  const CommandCell = (props) => {
    const { dataItem } = props;
    return (
      <td className="k-command-cell" rowSpan={props.rowSpan}>
        <div id="remove-icon" className="config-components-popup-button">
          <SmallOrangeButton
            onClick={() => {
              popUpCommandColumn(dataItem);
            }}
            title="Rateio de Plano de Contas e/ou Centro de Custo"
          >
            <img src={DivideIcon} alt="Dividir" />
          </SmallOrangeButton>
        </div>
      </td>
    );
  };

  const DeleteCell = (props) => {
    const { dataItem } = props;
    return (
      <td className="k-command-cell" rowSpan={props.rowSpan}>
        <div id="remove-icon" className="config-components-popup-button">
          <SmallOrangeButton
            onClick={() => {
              props.remove(dataItem);
            }}
            title="Excluir"
          >
            <img src={DeleteIcon} alt="Excluir" />
          </SmallOrangeButton>
        </div>
      </td>
    );
  };

  const enterEdit = (dataItem, field) => {
    const newData = data.map((item) => ({
      ...item,
      [EDIT_FIELD]: item.Id === dataItem.Id ? field : undefined
    }));
    setData(newData);
  };

  const exitEdit = () => {
    const newData = data.map((item) => {
      if (onItemChange !== undefined) item = onItemChange(item);
      return {
        ...item,
        [EDIT_FIELD]: undefined
      };
    });
    setData(newData);
  };

  const itemChange = (event) => {
    const field = event.field || '';
    const newData = data.map((item) => {
      if (item.Id === event.dataItem.Id) {
        item[field] = event.value;
      }
      return item;
    });
    setData(newData);
    setChanges(true);
  };

  const processItems = (items, column) => {
    let looped = 1;
    for (let i = 0; i < items.length; i += looped) {
      let rowSpan = 1;
      looped = 1;
      for (let j = i + 1; j < items.length; j++) {
        if (items[i][column] === items[j][column]) {
          looped++;
          rowSpan++;
        } else {
          break;
        }
      }
      const aux = looped === 1 ? 1 : undefined;
      items[i].cellRowSpan = rowSpan === 1 ? aux : rowSpan;
    }
    return items;
  };

  data = processItems(data, rowSpanField);

  const GridCommandCell = (props) => {
    const { dataItem, field } = props;
    if (dataItem.cellRowSpan === 1) {
      return <CommandCell {...props} command={command} rowSpan={dataItem.cellRowSpan} />;
    }
    if (dataItem.cellRowSpan > 1) {
      return <DeleteCell {...props} remove={remove} rowSpan={dataItem.cellRowSpan} />;
    }
    return null;
  };

  const customCellRender = (cell, props) => {
    const { dataItem, field } = props;

    if (field === rowSpanField) {
      if (dataItem.cellRowSpan) {
        return (
          <td {...cell.props} rowSpan={dataItem.cellRowSpan} className={dataItem.className}>
            {cell.props.children}
          </td>
        );
      }
      return null;
    }
    if (props.editor === 'numeric') {
      const newProps = { ...cell.props, style: { textAlign: 'right' } };
      const newTd = { ...cell, props: newProps };
      cell = newTd;
    }
    return (
      <CellRender originalProps={props} td={cell} enterEdit={enterEdit} editField={EDIT_FIELD} />
    );
  };

  const customRowRender = (tr, props) => (
    <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />
  );

  return (
    <Grid
      {...other}
      data={data}
      dataItemKey="Id"
      onItemChange={itemChange}
      cellRender={customCellRender}
      rowRender={customRowRender}
      editField={EDIT_FIELD}
      resizable={true}
      className="grid-rowspan"
    >
      <GridNoRecords>Sem registros</GridNoRecords>
      {children}
      {commandColumn && <GridColumn cell={GridCommandCell} editable={false} width={50} />}
    </Grid>
  );
};

GridRowSpan.propTypes = {
  commandColumn: PropTypes.bool,
  onItemCommand: PropTypes.func
};

GridRowSpan.defaultProps = {
  commandColumn: false,
  onItemCommand: () => {
    // do nothing.
  }
};

export default GridRowSpan;
