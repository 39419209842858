import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import * as _ from 'lodash';
import { AuthContext } from '../auth/AuthContext';
import { getTokenFromStorage, TOKENS } from '../auth/Tokens';
import Layout from '../layout';
import carteira from '../tmp/CarteiraScreen';
import loginDev from '../tmp/LoginScreen';
import login from './login';
import Error from './error';
import Help from './help';
import Load from './load';
import Invoices from './invoices';
import InvoiceRegistration from './invoices/invoiceRegistration';
import Profile from './profile';
import Services from './services';
import ServiceRegistration from './services/serviceRegistration';
import TaxClasses from './taxClasses';
import TaxClassRegistration from './taxClasses/taxClassRegistration';
import { recoverUserByJwt } from '../services/user';
import { recoverWalletByJwt } from '../services/wallet';
import Company from './settings/company';
import Documents from './settings/documents';
import Certificate from './settings/certificate';
import Classification from './settings/classification';
import Card from './settings/card';
import Test from './settings/test';
import Contracts from './contracts';
import ContractRegistration from './contracts/contractRegistration';
import GenerateInvoice from './contracts/generateInvoice';
import Attendances from './attendances';
import AttendanceRegistration from './attendances/attendanceRegistration';
import Proposals from './proposals';
import ProposalRegistration from './proposals/proposalRegistration';
import DocumentRegistration from './settings/documents/documentRegistration';
import Expenses from './expenses';
import ExpenseRegistration from './expenses/expenseRegistration';
import HumanResources from './humanResources';
import HumanRegistration from './humanResources/humanRegistration';
import Products from './products';
import ProductRegistration from './products/productRegistration';
import Shopping from './shopping';
import Home from './home';

const isDev = process.env.REACT_APP_ENV === 'development';

const View = () => {
  const { user, walletSelected, setUser, setWalletSelected } = useContext(AuthContext);
  const isAuthenticated = !!user;
  const isWalletSelected = !_.isEmpty(walletSelected);

  useEffect(() => {
    const fetchByJwts = async () => {
      const { data: userData } = await recoverUserByJwt();
      const walletData = await recoverWalletByJwt();

      setUser(userData);
      setWalletSelected(walletData);
    };

    const userJwt = getTokenFromStorage(TOKENS.USER.TOKEN);
    const walletJwt = getTokenFromStorage(TOKENS.WALLET.TOKEN);

    if (userJwt && walletJwt) fetchByJwts();
    else if (!isDev) {
      // eslint-disable-next-line no-undef
      window.location.replace(process.env.REACT_APP_LOGIN_URL);
    }
  }, []);

  const hasPermission = (value) => {
    let permissions = [];
    if (walletSelected?.ListaPermissoes) permissions = walletSelected.ListaPermissoes;
    if (permissions.includes('1')) return true;
    return permissions.includes(value);
  };

  const homePage = () => {
    if (hasPermission('41')) return <Home />;
    if (hasPermission('28')) return <Attendances />;
    if (hasPermission('29')) return <Contracts />;
    if (hasPermission('30')) return <Invoices />;
    if (hasPermission('31')) return <Expenses />;
    if (hasPermission('32')) return <HumanResources />;
    if (hasPermission('33')) return <Services />;
    let simplesNacional = false;
    if (walletSelected?.CarteiraBusiness?.SimplesNacional || walletSelected?.CarteiraBusiness?.MEI)
      simplesNacional = true;
    if (hasPermission('34') && !simplesNacional) return <TaxClasses />;
    if (hasPermission('35')) return <Proposals />;
    if (hasPermission('36')) return <Company />;
    if (hasPermission('37')) return <Documents />;
    if (hasPermission('38')) return <Certificate />;
    if (hasPermission('39')) return <Classification />;
    if (hasPermission('40')) return <Card />;
    return <Help />;
  };

  return (
    <Router>
      <Switch>
        <ProtectedLogin
          walletSelected={isWalletSelected}
          isAuthenticated={isAuthenticated}
          path="/login"
          component={login}
          exact
        />

        <ProtectedLogin
          walletSelected={isWalletSelected}
          isAuthenticated={isAuthenticated}
          path="/loginDev"
          component={loginDev}
          exact
        />

        <ProtectedSelecaoCarteira
          walletSelected={isWalletSelected}
          isAuthenticated={isAuthenticated}
          path="/selecionaCarteira"
          component={isDev ? carteira : Load}
          exact
        />

        <Layout>
          <Switch>
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/"
              component={homePage}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/home"
              component={homePage}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/profile"
              component={Profile}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/services"
              component={Services}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/services/serviceRegistration"
              component={ServiceRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/services/editService/:id"
              component={ServiceRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/taxClasses"
              component={TaxClasses}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/taxClasses/taxClassRegistration"
              component={TaxClassRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/taxClasses/editTaxClass/:id"
              component={TaxClassRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/help"
              component={Help}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/invoices"
              component={Invoices}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/invoices/:id"
              component={Invoices}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/invoices/invoiceRegistration/:id"
              component={InvoiceRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/settings/company"
              component={Company}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/settings/documents"
              component={Documents}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/settings/documents/documentRegistration"
              component={DocumentRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/settings/documents/editDocument/:id"
              component={DocumentRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/settings/certificate"
              component={Certificate}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/settings/classification"
              component={Classification}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/settings/card"
              component={Card}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/settings/test"
              component={Test}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/contracts"
              component={Contracts}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/contracts/:id"
              component={Contracts}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/contracts/contractRegistration/:id"
              component={ContractRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/contracts/generateInvoice/:id"
              component={GenerateInvoice}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/attendances"
              component={Attendances}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/attendances/:id"
              component={Attendances}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/attendances/attendanceRegistration/:id"
              component={AttendanceRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/attendances/generateInvoice"
              component={GenerateInvoice}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/proposals"
              component={Proposals}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/proposals/proposalRegistration"
              component={ProposalRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/proposals/proposalRegistration/:id"
              component={ProposalRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/expenses"
              component={Expenses}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/expenses/:id"
              component={Expenses}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/expenses/expenseRegistration/:id"
              component={ExpenseRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/humanResources"
              component={HumanResources}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/humanResources/:id"
              component={HumanResources}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/humanResources/humanRegistration/:id"
              component={HumanRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/products"
              component={Products}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/products/:id"
              component={Products}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/products/productRegistration/:id"
              component={ProductRegistration}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/shopping"
              component={Shopping}
              exact
            />
            <ProtectedRoute
              walletSelected={isWalletSelected}
              isAuthenticated={isAuthenticated}
              path="/"
              component={Error}
            />
          </Switch>
        </Layout>
      </Switch>
    </Router>
  );
};

const ProtectedRoute = ({ isAuthenticated, walletSelected, component: Component, ...others }) => (
  <Route
    {...others}
    render={() => {
      if (isAuthenticated) {
        if (walletSelected) {
          return <Component />;
        }

        return <Redirect to="/selecionaCarteira" />;
      }
      if (isDev) return <Redirect to="/loginDev" />;
      return <Redirect to="/login" />;
    }}
  />
);

const ProtectedLogin = ({ isAuthenticated, walletSelected, component: Component, ...others }) => (
  <Route
    {...others}
    render={() => {
      if (isAuthenticated) {
        if (walletSelected) return <Redirect to="/" />;

        return <Redirect to="/selecionaCarteira" />;
      }

      return <Component />;
    }}
  />
);

const ProtectedSelecaoCarteira = ({
  isAuthenticated,
  walletSelected,
  component: Component,
  ...others
}) => (
  <Route
    {...others}
    render={() => {
      if (isAuthenticated) {
        if (walletSelected) return <Redirect to="/" />;

        return <Component />;
      }

      return <Redirect to="/login" />;
    }}
  />
);

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  walletSelected: PropTypes.bool.isRequired
};

ProtectedLogin.propTypes = {
  component: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  walletSelected: PropTypes.bool.isRequired
};

ProtectedSelecaoCarteira.propTypes = {
  component: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  walletSelected: PropTypes.bool.isRequired
};

ProtectedSelecaoCarteira.defaultProps = {
  component: () => <></>
};

ProtectedRoute.defaultProps = {
  component: () => <></>
};

ProtectedLogin.defaultProps = {
  component: () => <></>
};

export default View;
