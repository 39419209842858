import { calculateFinalDate, getDateDiffInDays, DateNow } from './date';

export const invoiceStatus = (props) => {
  let td = '';
  switch (props) {
    case 'Não emitida':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Documento não emitido"
          style={{ color: 'gray' }}
        />
      );
      break;
    case 'Emitida':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Documento emitido"
          style={{ color: 'limegreen' }}
        />
      );
      break;
    case 'Cancelada':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Documento cancelado"
          style={{ color: 'red' }}
        />
      );
      break;
    default:
      td = (
        <span
          className="k-icon k-i-circle"
          title=""
          style={{ color: 'gray' }}
        />
      );
  }
  return <td style={{ textAlign: 'center' }}> {td} </td>;
};

export const invoiceStatusText = (code) => {
  switch (code) {
    case 'N': return 'Não emitida';
    case 'S': return 'Emitida';
    case 'C': return 'Cancelada';
    default: return '';
  };
};

export const contractStatus = (props) => {
  let td = '';
  switch (props) {
    case 'Vencido':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Vencido"
          style={{ color: 'red' }}
        />
      );
      break;
    case 'Em dia':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Em dia"
          style={{ color: 'limegreen' }}
        />
      );
      break;
    case 'A vencer':
      td = (
        <span
          className="k-icon k-i-circle"
          title="A vencer"
          style={{ color: 'gold' }}
        />
      );
      break;
    default:
      td = '';
  }
  return <td style={{ textAlign: 'center' }}> {td} </td>;
};

export const contractStatusText = (code) => {
  switch (code) {
    case 'V':
      return 'Vencido';
    case 'E':
      return 'Em dia';
    case 'A':
      return 'A vencer';
    default:
      return '';
  }
};

export const issuedStatus = (props) => {
  let td = '';
  switch (props) {
    case false:
      td = '';
      break;
    case true:
      td = (
        <span
          className="k-icon k-i-check"
          title="Em dia"
          style={{ color: '#e3a14b' }}
        />
      );
      break;
    default:
      td = '';
  }
  return <td style={{ textAlign: 'center' }}> {td} </td>;
};

export const paymentStatus = (props) => {
  let td = '';
  switch (props) {
    case false:
      td = '';
      break;
    case true:
      td = (
        <span
          className="k-icon k-i-check-circle"
          title="Em dia"
          style={{ color: 'limegreen' }}
        />
      );
      break;
    default:
      td = '';
  }
  return <td style={{ textAlign: 'center' }}> {td} </td>;
};

export const calculateReadjustStatus = (payments) => {
  if (payments !== null && payments !== undefined && payments.length > 0) {
    const today = DateNow();
    payments = payments.sort((a, b) => {
      return new Date(a.DataVencimento) - new Date(b.DataVencimento);
    });
    const diff = getDateDiffInDays(today, new Date(payments[payments.length - 1].DataVencimento));
    if (diff > 60) return 'E';
    else if (diff < 0) return 'V';
    else if (diff >= 0 && diff <= 60) return 'A';
  }
  return '';
};

export const calculatePaymentStatus = (payments) => {
  if (payments !== null && payments !== undefined && payments.length > 0) {
    const today = DateNow();
    payments = payments.sort((a, b) => {
      return new Date(a.DataVencimento) - new Date(b.DataVencimento);
    });
    const diff = getDateDiffInDays(today, new Date(payments[0].DataVencimento));
    if (diff > 1) return 'E';
    else if (diff < 0) return 'V';
    else if (diff == 0 || diff == 1) return 'A';
  }
  return '';
};

export const attendanceStatus = (props) => {
  let td = '';
  switch (props) {
    case 'Em aberto':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Em aberto"
          style={{ color: 'gray' }}
        />
      );
      break;
    case 'Atendido':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Atendido"
          style={{ color: 'limegreen' }}
        />
      );
      break;
    case 'Atendido Parcialmente':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Atendido Parcialmente"
          style={{ color: 'gold' }}
        />
      );
      break;
    default:
      td = '';
  }
  return <td style={{ textAlign: 'center' }}> {td} </td>;
};

export const generatedStatus = (props) => {
  let td = '';
  switch (props) {
    case 'Não':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Em aberto"
          style={{ color: 'gray' }}
        />
      );
      break;
    case 'Sim':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Gerado"
          style={{ color: 'limegreen' }}
        />
      );
      break;
    default:
      td = '';
  }
  return <td style={{ textAlign: 'center' }}> {td} </td>;
};

export const generatedStatusText = (code) => {
  switch (code) {
    case 'N':
      return 'Não';
    case 'S':
      return 'Sim';
    default:
      return '';
  }
};

export const invoiceIssuedStatus = (props) => {
  let td = ''
  if (props)
    td = (
      <span
        className="k-icon k-i-circle"
        title="Importada"
        style={{ color: 'limegreen' }}
      />
    );
  else
    td = (
      <span
        className="k-icon k-i-circle"
        title="Não Importada"
        style={{ color: 'gold' }}
      />
    );
  return <td style={{ textAlign: 'center' }}> {td} </td>;
};

export const invoiceAttendanceStatus = (props) => {
  let td = null;
  if (props > 0)
    td = (
      <span
        className="k-icon k-i-file-ppt"
        title={`Proposta  Nº ${props} `}
        style={{ color: 'green' }}
      />
    );
  return <td style={{ textAlign: 'center' }}> {td} </td>;
};

export const productStatus = (props) => {
  let td = '';
  switch (props) {
    case 'Ativo':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Ativo"
          style={{ color: 'limegreen' }}
        />
      );
      break;
    case 'Descontinuado':
      td = (
        <span
          className="k-icon k-i-circle"
          title="Descontinuado"
          style={{ color: 'red' }}
        />
      );
      break;
    default:
      td = '';
  }
  return <td style={{ textAlign: 'center' }}> {td} </td>;
};

export const productStatusText = (props) => {
  if (props) return 'Descontinuado'
  return 'Ativo'
};
