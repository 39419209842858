import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getAll = () => axiosWalletRequest('CarteiraBusiness', METHOD.GET);

export const getById = (id) => axiosWalletRequest(`CarteiraBusiness/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('CarteiraBusiness', METHOD.POST, body);

export const exclude = (id) => axiosWalletRequest(`CarteiraBusiness/${id}`, METHOD.DELETE);

export const update = (id, body) => axiosWalletRequest(`CarteiraBusiness/${id}`, METHOD.PUT, body);

export const updateCertificate = (file) => axiosWalletRequest('/CarteiraBusiness/Certificado', METHOD.POST, file);

export const deleteCertificate = () => axiosWalletRequest('/CarteiraBusiness/RemoverCertificado', METHOD.DELETE);

export const updateSellClassification = (id, body) => axiosWalletRequest(`/CarteiraBusiness/AtualizarConfiguracaoVenda/${id}`, METHOD.PUT, body);

export const getSaleSettings = (id) => axiosWalletRequest(`/CarteiraBusiness/ListarConfiguracaoVenda/${id}`, METHOD.GET);

export const updateSettings = (id, body) => axiosWalletRequest(`CarteiraBusiness/Configuracoes/${id}`, METHOD.PUT, body);