import PropTypes from 'prop-types';
import './style.css';

const ContentNotification = ({ title, content }) => (
  <>
    <h3>{title}</h3>
    <p>{content}</p>
  </>
);

ContentNotification.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default ContentNotification;
