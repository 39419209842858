import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { successNotification, errorNotification } from '../../notification';
import { FormFieldDrop, FormFieldGeneric } from '../../formField';
import FormRow from '../../formRow';
import Popup from '../layout';
import * as contractService from '../../../services/contract';
import { useForms } from '../../../hooks';
import PopUpError from '../popUpError';

const initialForm = {
  Nome: ''
};

const PopUpContractType = ({ visible, setVisible, reloadContractTypes, isEdit, entity }) => {
  const [form, updateForm, setForm] = useForms(initialForm);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');

  const createContractType = async (body) => {
    const response = await contractService.createContractType(body);
    const { data } = response;
    if (response.status === 200) {
      body = {
        ...body,
        Id: data,
        Editavel: true
      };
      successNotification('Tipo de contrato criado com sucesso!');
      await reloadContractTypes(body);
    } else {
      const { data } = response;
      let erro = '';
      if (data.MensagemUsuario !== undefined) erro = data.MensagemUsuario;
      else erro = 'Houve um erro ao tentar criar um tipo de contrato.';
      return [erro];
    }
    return null;
  };

  const editContractType = async (body) => {
    const response = await contractService.updateContractType(body, body.Id);
    if (response.status === 200) {
      body = {
        Id: body.Id,
        Editavel: true,
        ...body
      };
      successNotification('Tipo de contrato atualizado com sucesso!');
      await reloadContractTypes(body);
    } else {
      const { data } = response;
      let erro = '';
      if (data.MensagemUsuario !== undefined) erro = data.MensagemUsuario;
      else erro = 'Houve um erro ao tentar atualizar um tipo de contrato.';
      return [erro];
    }
    return null;
  };

  const excludeContractType = async () => {
    const response = await contractService.excludeContractType(entity.Id);
    if (response.status === 200) {
      successNotification('Tipo de contrato excluído com sucesso!');
      await reloadContractTypes(null);
    } else {
      const { data } = response;
      let erro = '';
      if (data.MensagemUsuario !== undefined) erro = data.MensagemUsuario;
      else erro = 'Houve um erro ao tentar excluir um tipo de contrato.';
      return [erro];
    }
    return null;
  };

  const submitHandle = () => {
    const body = {
      Id: entity?.Id,
      ...form
    };

    return isEdit ? editContractType(body) : createContractType(body);
  };

  useEffect(() => {
    setForm(entity || initialForm);
  }, [entity, visible]);

  return (
    <Popup
      visible={visible}
      setVisible={setVisible}
      title="Tipo de Contrato"
      confirmText="Salvar"
      cancelText="Cancelar"
      haveExclude={isEdit}
      onConfirm={submitHandle}
      onDelete={excludeContractType}
    >
      <FormRow>
        <FormFieldGeneric
          titleLabel="Nome"
          classNameWrapper="fill-100-field-tooltip"
          name="Nome"
          onChangeValue={updateForm}
          valueInput={form.Nome}
          required
        />
      </FormRow>
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title="Erro ao Consultar Nota Fiscal"
        message={popUpErrorMessage}
      />
    </Popup>
  );
};

PopUpContractType.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  reloadContractTypes: PropTypes.func.isRequired
};

export default PopUpContractType;
