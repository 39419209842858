import PropTypes from 'prop-types';
import React from 'react';
import Popup from '../layout';

const PopUpError = ({ visible, setVisible, title, message }) => {
  let lines = '';
  if (Array.isArray(message)) {
    lines = message.map((item, i) => (
      <>
        <br />
        <span style={{ marginTop: '10px' }} key={i}>
          {item}
        </span>
      </>
    ));
  } else lines = <span style={{ marginTop: '10px' }}> {message} </span>;
  return (
    <Popup
      title={title}
      width={700}
      visible={visible}
      setVisible={setVisible}
      cancelText="OK"
      haveConfirm={false}
    >
      {lines}
    </Popup>
  );
};

PopUpError.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpError;
