import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getAll = () => axiosWalletRequest('Venda/', METHOD.GET);

export const getByDate = (dateInverval) => axiosWalletRequest('Venda/Filtrar', METHOD.POST, dateInverval);

export const saleDashboard = (dateInverval) => axiosWalletRequest('Venda/Dashboard', METHOD.POST, dateInverval);

export const getById = (id) => axiosWalletRequest(`Venda/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('Venda', METHOD.POST, body);

export const exclude = (id) => axiosWalletRequest(`Venda/${id}`, METHOD.DELETE);

export const update = (id, body) => axiosWalletRequest(`Venda/${id}`, METHOD.PUT, body);

export const calculateTax = (sale) => axiosWalletRequest('Venda/CalcularImposto', METHOD.POST, sale);

export const getTransactionNatures = () => axiosWalletRequest(`Venda/ListarNaturezaOperacao`, METHOD.GET);

export const getInvoiceNumber = async (id) => {
    const { data } = await axiosWalletRequest(`Venda/ObterNumeroVenda/${id}`, METHOD.GET);
    return data;
}

export const generateDiscrimination = (sale) => axiosWalletRequest('Venda/GerarDiscriminacaoNota', METHOD.POST, sale);

export const getLast = async () => {
    const { data } = await axiosWalletRequest(`Venda/ObterUltimaVendaPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}

export const getFirst = async () => {
    const { data } = await axiosWalletRequest(`Venda/ObterPrimeiraVendaPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}

export const generateBill = (id) => axiosWalletRequest(`Venda/GerarBoleto/${id}`, METHOD.GET);

export const printBill = (id) => axiosWalletRequest(`Venda/ImprimirBoleto/${id}`, METHOD.GET);

export const exportExcel = (dateInverval) => axiosWalletRequest('Venda/ExportarExcel', METHOD.POST, dateInverval);

export const generatePix = (id) => axiosWalletRequest(`Venda/GerarPix/${id}`, METHOD.GET);

export const printPix = (id) => axiosWalletRequest(`Venda/ImprimirPix/${id}`, METHOD.GET);

export const updatePayment = (id, body) => axiosWalletRequest(`Venda/AtualizarPagamento/${id}`, METHOD.PUT, body);

