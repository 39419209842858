import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import { CalendarWithButtons } from '../../components/dateComponents';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpError from '../../components/popUps/popUpError';
import Pagination from '../../components/pagination';
import './styles.css';
import LoadScreen from '../load';
import * as contractService from '../../services/contract';
import { successNotification, warningNotification } from '../../components/notification';
import { contractStatus, contractStatusText } from '../../utils/status';
import PopUpFiles from '../../components/popUps/popUpFiles';

const sizes = [
  { Id: 0, Value: 5, Description: '5' },
  { Id: 1, Value: 15, Description: '15' },
  { Id: 2, Value: 30, Description: '30' },
  { Id: 3, Value: 50, Description: '50' },
  { Id: 4, Value: 100, Description: '100' },
  { Id: 5, Value: 250, Description: '250' },
  { Id: 6, Value: -1, Description: 'Todos' }
];

const Contracts = () => {
  const { id } = useParams();
  const history = useHistory();
  const [contracts, setContracts] = useState([]);
  const [contractsSelectedsIds, setContractsSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpConfirmDelete, setPopUpConfirmDelete] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [rowIndex, setRowIndex] = useState(0);
  const [filterValue, setFilterValue] = useState({
    field: 'Situacao',
    operator: 'equals',
    value: 'Ativo'
  });

  const [popUpFile, setPopUpFile] = useState(false);
  const [idAttach, setIdAttach] = useState(null);

  const columns = [
    {
      field: 'Situacao',
      title: 'Sit.',
      width: 60,
      columnMenuCheckbox: true
    },
    {
      field: 'ReajusteStatus',
      title: 'Reaj.',
      width: 60,
      cell: (props) => {
        const td = contractStatus(props.dataItem.ReajusteStatus);
        return td;
      },
      columnMenuCheckbox: true
    },
    {
      field: 'PagamentoStatus',
      title: 'Pagto.',
      width: 60,
      cell: (props) => {
        const td = contractStatus(props.dataItem.PagamentoStatus);
        return td;
      },
      columnMenuCheckbox: true
    },
    {
      field: 'Id',
      title: 'Anexos',
      width: 60,
      cell: (props) => {
        let td = null;
        const cellClick = {
          onClick: () => {
            setIdAttach(props.dataItem.Id);
            setPopUpFile(true);
          }
        };
        if (props.dataItem.PossuiAnexo) {
          td = (
            <td style={{ textAlign: 'center' }}>
              <button type="button" className="general-button" {...cellClick}>
                <span className="k-icon k-font-icon k-i-attachment" />
              </button>
            </td>
          );
        } else {
          td = <td style={{ textAlign: 'center' }} {...cellClick} />;
        }
        return td;
      }
    },
    {
      field: 'Numero',
      title: 'Núm.',
      width: 60,
      cell: (props) => <td style={{ textAlign: 'right' }}>{props.dataItem.Numero}</td>,
      columnMenuCheckbox: true
    },
    {
      field: 'PessoaNome',
      title: 'Nome',
      width: 250,
      columnMenuCheckbox: true,
      countCell: true
    },
    {
      field: 'ParcelaPeriodo',
      title: 'Period.',
      columnMenuCheckbox: true
    },
    {
      field: 'TipoContratoNome',
      title: 'Tipo',
      columnMenuCheckbox: true
    },
    {
      field: 'Valor',
      title: 'Val. (R$)',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}',
      width: 80,
      columnMenu: true,
      totalCell: true
    },
    {
      field: 'Data',
      title: 'Data de Cadastro',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      columnMenu: true
    },
    {
      field: 'DataInicio',
      title: 'Data de Inicio',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      columnMenu: true
    },
    {
      field: 'DataFim',
      title: 'Data de Fim',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      columnMenu: true
    }
  ];

  const seekScroll = async (data) => {
    if (id != null && id !== '0') {
      const index = data.findIndex((item) => item.Id === id);
      if (index >= 0) {
        setRowIndex(index);
        return;
      }
    }
    if (data.length > 0) setRowIndex(data.length - 1);
    else setRowIndex(0);
  };

  const fetchContracts = async () => {
    const pageInterval = {
      NumeroPagina: 1,
      TamanhoPagina: -1,
      Nome: null
    };
    let { data } = await contractService.getByPagination(pageInterval);
    data = data.map((item) => {
      const data = new Date(item.Data);
      const dataInicio = new Date(item.DataInicio);
      const dataFim = new Date(item.DataFim);
      data.setHours(0, 0, 0, 0);
      dataInicio.setHours(0, 0, 0, 0);
      dataFim.setHours(0, 0, 0, 0);
      return {
        ...item,
        Data: data,
        DataInicio: dataInicio,
        DataFim: dataFim,
        ReajusteStatus: contractStatusText(item.ReajusteStatus),
        PagamentoStatus: contractStatusText(item.PagamentoStatus)
      };
    });
    setContracts(data);
    seekScroll(data);
  };

  const excludeContracts = async () => {
    setLoading(true);
    const excludeContractsRequests = contractsSelectedsIds.map((contractSelected) =>
      contractService.exclude(contractSelected)
    );

    const excludeContractsResponses = await Promise.all(excludeContractsRequests);

    const isAllRequestsRight = excludeContractsResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    setPopUpConfirmDelete(false);
    if (isAllRequestsRight) successNotification('Contrato(s) excluído(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir uma ou mais contratos:'];
      excludeContractsResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      setPopUpErrorTitle('Erro ao Excluir Contrato');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchContracts();
  };

  useEffect(async () => {
    setLoading(true);
    await fetchContracts();
    setLoading(false);
  }, []);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="contract-list">
        <h1>Contrato</h1>
        <div className="buttons-contract-list">
          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
            url="/contracts/contractRegistration/0"
          />

          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (contractsSelectedsIds.length > 0) setPopUpConfirmDelete(true);
                  else warningNotification('Nenhum contrato selecionado');
                },
                text: 'Excluir Contrato'
              },
              {
                id: INTERNAL_ITEMS.BOLETA,
                onClick: () => {
                  history.push(`/contracts/generateInvoice/0`);
                },
                text: 'Gerar Documento',
                style: { backgroundColor: '#000000' }
              }
            ]}
          />
        </div>
        <Table
          name="Contracts"
          data={contracts}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setContractsSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => {
            window.localStorage.setItem('EDIT_ID', Id);
            history.push(`/contracts/contractRegistration/${Id}`);
          }}
          sortable={true}
          rowIndex={rowIndex}
          filterValue={filterValue}
        />
      </div>
      <PopUpConfirm
        onConfirm={excludeContracts}
        visible={popUpConfirmDelete}
        setVisible={setPopUpConfirmDelete}
        title="Confirmação"
        message="Você tem certeza que deseja excluir o(s) contratos(s) selecionado(s)?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpFiles
        visible={popUpFile}
        setVisible={setPopUpFile}
        title="Anexos"
        id={idAttach}
        afterAttaching={async () => {
          setLoading(true);
          setIdAttach(null);
          await fetchContracts();
          setLoading(false);
        }}
        service={contractService}
      />
    </>
  );
};

export default Contracts;
