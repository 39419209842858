import PropTypes from 'prop-types';
import React from 'react';
import '../style.css';
import './style.css';

const WhiteButton = ({ children, className, ...other }) => (
  <button
    {...other}
    type="button"
    className={`white-button box-button general-button ${className}`}
  >
    {children}
  </button>
);

WhiteButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

WhiteButton.defaultProps = {
  className: ''
};

export default WhiteButton;
