import React from 'react';
import IconeContato from '../../assets/icone-contato.svg';
import IconeManual from '../../assets/icone-manual.svg';
import IconePrimeirosPassos from '../../assets/icone-primeiros-passos.svg';
import IconeSobreAPP from '../../assets/icone-sobre-a-PP.svg';
import HelpCard from '../../components/helpCard';
import './style.css';
import Card from '../../components/card';

const Help = () => (
  <div id="help-page">
    <h1 id="page-title">Ajuda</h1>
    <div id="container-grid">
      <HelpCard title="Contate-nos" logo={IconeContato}>
        <div className="text-card">
          <p>Entre em contato por email ou pelo canal de atendimento:</p>
          <p>Email: paiva@paiva.com.br</p>
          <p>Telefone/Whatsapp: (31) 99430-2079</p>
        </div>
        <div className="div-link">
          <a
            className="link"
            href="http://www.paiva.com.br/ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Acesse nosso canal de Atendimento
          </a>
        </div>
      </HelpCard>

      <HelpCard title="Manual Next Business" logo={IconeManual}>
        <div className="text-card">
          <p>Alguma dúvida?</p>
          <p>
            Nosso Manual te ajuda a entender como utilizar o Business e melhorar seu gerenciamento
            financeiro.
          </p>
        </div>
        <div className="div-link">
          <a
            className="link"
            href="http://paivapiovesan.com.br/manualnext/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Leia o manual
          </a>
        </div>
      </HelpCard>

      <HelpCard title="Sobre a Paiva Piovesan" logo={IconeSobreAPP}>
        <div className="text-card">
          <p>
            A Paiva Piovesan tem sua sede no município de Belo Horizonte, estado de Minas Gerais.
            Fundada em dezembro de 1990, tem como missão desenvolver soluções inovadoras em
            softwares de gestão, que permitam aos clientes ganhos de produtividade.
          </p>
        </div>
        <div className="div-link">
          <a
            className="link"
            href="http://paivapiovesan.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visite nosso site
          </a>
        </div>
      </HelpCard>

      <HelpCard title="Primeiros passos" logo={IconePrimeirosPassos}>
        <div className="text-card">
          <p>Não sabe como realizar alguma tarefa?</p>
          <p>Confira nossos tutoriais para aprender a utilizar as ferramentas do Finance. </p>
        </div>
        <div className="div-link">
          <a
            className="link"
            href="http://paivapiovesan.com.br/manualnext/base-manuais/primeiros-passos-business/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Aprenda os primeiros passos
          </a>
        </div>
      </HelpCard>
    </div>
  </div>
);

export default Help;
