import { Popup } from '@progress/kendo-react-popup';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import info from '../../../../assets/info-tooltip.svg';
import './style.css';

const Information = ({ msg }) => {
  const anchor = useRef(null);
  const [show, setShow] = useState(false);

  const anchorAlign = {
    horizontal: 'center',
    vertical: 'top'
  };

  const popupAlign = {
    horizontal: 'center',
    vertical: 'bottom'
  };

  const setShowFalse = () => {
    setShow(false);
  };

  return (
    <>
      <button
        type="button"
        className="custom-button"
        onClick={() => setShow(!show)}
        onBlur={setShowFalse}
      >
        <img src={info} alt="Informação" ref={anchor} />
      </button>

      <Popup
        anchor={anchor.current}
        show={show}
        animate={false}
        anchorAlign={anchorAlign}
        popupAlign={popupAlign}
        className="info-popup"
      >
        <p className="popup-info-content">{msg}</p>
      </Popup>
    </>
  );
};

Information.propTypes = {
  msg: PropTypes.string.isRequired
};
export default Information;
