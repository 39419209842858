/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { groupBy } from '@progress/kendo-data-query';
import {
  setExpandedState,
  setGroupIds,
} from '@progress/kendo-react-data-tools';
import Table from '../../../components/table';
import { Button } from '@progress/kendo-react-buttons';
import './style.css';
import LoadScreen from '../../load';
import * as serviceService from '../../../services/service';
import GridInline from '../../../components/grid';
import { TotalCell } from '../../../components/grid/totalCell';
import FormRow from '../../../components/formRow';
import GenericField from '../../../components/formField/genericField';
import { PayRollItems } from '../../../utils/payRoll';
import FormFieldAutoComplete from '../../../components/formField/autoComplete';
import products from './products.json';
import GridGroup from '../../../components/gridGroup';

const initialGroup = [
  {
    field: 'Category.CategoryName'
  }
];

const Test = () => {
  return (
    <GridGroup initialGroup={initialGroup} data={products}>
      <Column field="ProductID" filterable={false} title="ID" width="50px" />
      <Column field="ProductName" title="Product Name" />
      <Column field="UnitPrice" title="Unit Price" filter="numeric" />
      <Column field="UnitsInStock" title="Units In Stock" filter="numeric" />
      <Column field="Category.CategoryName" title="Category Name" />
    </GridGroup>
  );
};

export default Test;
