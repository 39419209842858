import { axiosWalletRequest, METHOD } from '../api/Requests';

export const updateWallet = (body) => axiosWalletRequest('/Carteira', METHOD.PUT, body);

export const recoverWalletByJwt = async () => {
  const { data } = await axiosWalletRequest('/Carteira/CarteiraDoUsuarioAPartirDoJWT', METHOD.GET);
  return data;
}

export const resetAvatar = () => axiosWalletRequest('/Carteira/ResetarAvatar', METHOD.PUT);

export const updateAvatar = (binaryFile, type) => {
  return axiosWalletRequest('/Carteira/Avatar', METHOD.POST, binaryFile, {
    headers: {
      'Content-Type': type
    }
  });
}

export const resetLogo = () => axiosWalletRequest('/Carteira/ResetarLogo', METHOD.PUT);

export const updateLogo = (binaryFile, type) => {
  return axiosWalletRequest('/Carteira/Logo', METHOD.POST, binaryFile, {
    headers: {
      'Content-Type': type
    }
  });
}