import { axiosUserRequest, METHOD } from '../api/Requests';

export const recoverCompleteUser = () => axiosUserRequest('/Usuario/RecuperarUsuarioCompleto', METHOD.POST);

export const updateUser = (body) => axiosUserRequest('/Usuario', METHOD.PUT, body);

export const resetUserAvatar = () => axiosUserRequest('/Usuario/ResetarAvatar', METHOD.PUT);

export const updateAvatar = (binaryFile, type) => {
  return axiosUserRequest('/Usuario/Avatar', METHOD.POST, binaryFile, {
    headers: {
      'Content-Type': type
    }
  });
}

export const recoverUserByJwt = () => axiosUserRequest('/Usuario/RecuperarUsuarioPeloJWT', METHOD.GET);

export const disable = (body) => axiosUserRequest('/Usuario/Desativar', METHOD.PUT, body);
