import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForms } from '../../../hooks';
import { successNotification, errorNotification } from '../../notification';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import { create, update, exclude } from '../../../services/taxClass';
import FormRow from '../../formRow';
import Popup from '../layout';
import { parseFloatToApi } from '../../../utils/number';

const emptyEntity = {
  Nome: null,
  BaseDeCalculoISS: null,
  AliquotaISS: null,
  BaseDeCalculoRF: null,
  AliqPISRF: null,
  AliqCOFINSRF: null,
  AliqCSLLRF: null,
  AliqIRRFRF: null,
  AliqINSSRF: null
};

const PopUpClassTax = ({ visible, setVisible, isEdit, entity, reloadClassTaxes }) => {
  const [forms, updateFormValue, setForms, updateProperty] = useForms(emptyEntity);

  useEffect(() => {
    setForms(entity || emptyEntity);
  }, [entity, visible]);

  const createNewClassTax = async (body) => {
    try {
      await create(body, body.Id);
      successNotification('Classe de imposto criada com sucesso!');
      await reloadClassTaxes();
    } catch (error) {
      errorNotification('Houve um erro ao tentar criar a Classe de imposto');
    }
  };

  const editClassTax = async (body) => {
    try {
      await update(body, body.Id);
      successNotification('Classe de imposto alterada com sucesso!');
      await reloadClassTaxes();
    } catch (error) {
      errorNotification('Houve um erro ao tentar editar a Classe de imposto');
    }
  };

  const deleteClassTax = async () => {
    try {
      await exclude(forms);
      successNotification('Classe de imposto excluída com sucesso!');
      await reloadClassTaxes();
    } catch (error) {
      errorNotification('Houve um erro ao tentar excluir a Classe de imposto');
    }
  };

  const submitHandle = () => {
    const requestBody = {
      ...forms,
      BaseDeCalculoISS: parseFloatToApi(forms.BaseDeCalculoISS),
      AliquotaISS: parseFloatToApi(forms.AliquotaISS),
      BaseDeCalculoRF: parseFloatToApi(forms.BaseDeCalculoRF),
      AliqPISRF: parseFloatToApi(forms.AliqPISRF),
      AliqCOFINSRF: parseFloatToApi(forms.AliqCOFINSRF),
      AliqCSLLRF: parseFloatToApi(forms.AliqCSLLRF),
      AliqIRRFRF: parseFloatToApi(forms.AliqIRRFRF),
      AliqINSSRF: parseFloatToApi(forms.AliqINSSRF)
    };

    return isEdit ? editClassTax(requestBody) : createNewClassTax(requestBody);
  };

  return (
    <Popup
      width={900}
      visible={visible}
      setVisible={setVisible}
      title="Classe de Impostos"
      confirmText="Salvar"
      cancelText="Cancelar"
      haveExclude={isEdit}
      onConfirm={submitHandle}
      onDelete={deleteClassTax}
    >
      <GenericField
        titleLabel="Nome Classe de Impostos"
        classNameWrapper="fill-100-field"
        valueInput={forms.Nome}
        onChangeValue={updateFormValue}
        name="Nome"
        required
      />

      <FormRow>
        <GenericField
          titleLabel="Base de cálculo"
          iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
          classNameWrapper="fill-15-field"
          valueInput={forms.BaseDeCalculoISS}
          setValue={(val) => updateProperty('BaseDeCalculoISS', val)}
          name="BaseDeCalculoISS"
          required
        />

        <GenericField
          titleLabel="Alíquota"
          iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
          classNameWrapper="fill-15-field"
          valueInput={forms.AliquotaISS}
          setValue={(val) => updateProperty('AliquotaISS', val)}
          name="AliquotaISS"
          required
        />
      </FormRow>

      <FormRow>
        <h3>Retenções Federais</h3>
      </FormRow>

      <FormRow withoutMarginTop>
        <GenericField
          titleLabel="Base de cálculo"
          iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
          classNameWrapper="fill-15-field"
          valueInput={forms.BaseDeCalculoRF}
          setValue={(val) => updateProperty('BaseDeCalculoRF', val)}
          name="BaseDeCalculoRF"
        />
      </FormRow>

      <FormRow withShrink>
        <GenericField
          titleLabel="Aliq.PIS"
          iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
          classNameWrapper="fill-15-field"
          valueInput={forms.AliqPISRF}
          setValue={(val) => updateProperty('AliqPISRF', val)}
          name="AliqPISRF"
        />

        <GenericField
          titleLabel="Aliq.COFINS"
          iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
          classNameWrapper="fill-15-field"
          valueInput={forms.AliqCOFINSRF}
          setValue={(val) => updateProperty('AliqCOFINSRF', val)}
          name="AliqCOFINSRF"
        />

        <GenericField
          titleLabel="Aliq.CSLL"
          iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
          classNameWrapper="fill-15-field"
          valueInput={forms.AliqCSLLRF}
          setValue={(val) => updateProperty('AliqCSLLRF', val)}
          name="AliqCSLLRF"
        />

        <GenericField
          titleLabel="Aliq.IRRF"
          iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
          classNameWrapper="fill-15-field"
          valueInput={forms.AliqIRRFRF}
          setValue={(val) => updateProperty('AliqIRRFRF', val)}
          name="AliqIRRFRF"
        />

        <GenericField
          titleLabel="Aliq.INSS"
          iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
          enableReais
          classNameWrapper="fill-15-field"
          valueInput={forms.AliqINSSRF}
          setValue={(val) => updateProperty('AliqINSSRF', val)}
          name="AliqINSSRF"
        />
      </FormRow>
    </Popup>
  );
};

PopUpClassTax.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  entity: PropTypes.shape({
    Nome: PropTypes.string,
    BaseDeCalculoISS: PropTypes.number,
    AliquotaISS: PropTypes.number,
    BaseDeCalculoRF: PropTypes.number,
    AliqPISRF: PropTypes.number,
    AliqCOFINSRF: PropTypes.number,
    AliqCSLLRF: PropTypes.number,
    AliqIRRFRF: PropTypes.number,
    AliqINSSRF: PropTypes.number
  }),
  reloadClassTaxes: PropTypes.func
};

PopUpClassTax.defaultProps = {
  isEdit: false,
  entity: emptyEntity
};

export default PopUpClassTax;
