import React from 'react';
import Loader from '../../components/loader';
import './style.css';

export default function LoadScreen() {
  return (
    <div className="load-screen">
      <div className="loader-wrapper">
        <Loader />
      </div>
    </div>
  );
}
