import PropTypes from 'prop-types';
import React from 'react';
import { Calendar, MonthCalendar } from '../../dateComponents';
import { DateNow } from '../../../utils/date';
import Label from '../components/label';
import './styles.css';

const FormFieldDate = ({
  titleLabel,
  valueOnChange,
  disabled,
  className,
  value,
  enableIcon,
  onClickIcon,
  calendarIsMonth,
  required
}) => (
  <div className={`formFieldDateComponent ${className}`}>
    <Label enableIcon={enableIcon} onClickIcon={onClickIcon}>
      {required ? `${titleLabel}*` : `${titleLabel}`}
    </Label>
    {calendarIsMonth ? (
      <MonthCalendar
        date={value}
        setDate={valueOnChange}
        wrapperClassName="dateField"
        disabled={disabled}
        required={required}
        validationMessage="Preencha este campo."
      />
    ) : (
      <Calendar
        date={value}
        setDate={valueOnChange}
        wrapperClassName="dateField"
        disabled={disabled}
        required={required}
        validationMessage="Preencha este campo."
      />
    )}
  </div>
);

FormFieldDate.propTypes = {
  titleLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  valueOnChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  enableIcon: PropTypes.bool,
  onClickIcon: PropTypes.func,
  calendarIsMonth: PropTypes.bool,
  required: PropTypes.bool
};

FormFieldDate.defaultProps = {
  valueOnChange: undefined,
  disabled: false,
  className: '',
  value: DateNow(),
  enableIcon: false,
  calendarIsMonth: false,
  onClickIcon: () => {
    throw Error('Unexpected click');
  },
  required: false
};

export default FormFieldDate;
