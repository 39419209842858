import React from 'react';
import Label from '../components/label';
import './styles.css';

const LabelField = ({ titleLabel, classNameWrapper, valueLabel, required, ...others }) => (
  <>
    <div className={`wrapper-field ${classNameWrapper}`}>
      <Label> {required ? `${titleLabel}*` : `${titleLabel}`} </Label>
      <div className="label-field">
        <Label {...others}> {valueLabel} </Label>
      </div>
    </div>
  </>
);

export default LabelField;
