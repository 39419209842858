/* eslint no-restricted-syntax: 0 */
import React, { useContext, useEffect, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { useHistory, useParams } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormFieldDate from '../../../components/formField/date';
import FormFieldDrop from '../../../components/formField/drop';
import GenericField from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import ToggleExpense from '../../../components/toggleInvoice';
import { useForms } from '../../../hooks';
import * as saleService from '../../../services/sale';
import * as personService from '../../../services/person';
import * as transactionService from '../../../services/transaction';
import * as planAccountService from '../../../services/planAccount';
import * as centerCostService from '../../../services/centerCost';
import * as accountService from '../../../services/account';
import * as addressService from '../../../services/address';
import * as humanResourceService from '../../../services/humanResource';
import * as countryService from '../../../services/country';
import * as expenseService from '../../../services/expense';
import LoadScreen from '../../load';
import {
  successNotification,
  errorNotification,
  warningNotification
} from '../../../components/notification';
import PopupAddClassification from '../../../components/popUps/popUpAddClassification';
import PopupDivideClassification from '../../../components/popUps/popUpDivideClassification';
import GridInline from '../../../components/grid';
import DropDownCell from '../../../components/grid/dropDownCell';
import DateCell from '../../../components/grid/dateCell';
import { TotalCell, TotalValueCell, TotalTextCell } from '../../../components/grid/totalCell';
import GridRowSpan from '../../../components/grid/gridRowSpan';
import { calculateNewDateFrequency, DateNow } from '../../../utils/date';
import * as numberUtil from '../../../utils/number';
import './styles.css';
import PopUpFiles from '../../../components/popUps/popUpFiles';
import { INTERNAL_BUTTON } from '../../../components/button';
import PopUpAddPayRoll from '../../../components/popUps/popUpAddPayRoll';
import Supplier from '../../../components/supplier';
import PopUpConfirm from '../../../components/popUps/popUpConfirm';
import NumberCell from '../../../components/grid/numberCell';

const initialForm = {
  Data: DateNow(),
  Numero: '',
  DataVencimento: DateNow(),
  InformacaoAdicional: null
};

const HumanRegistration = () => {
  let { id } = useParams();
  const history = useHistory();
  const [form, updateProperty, setForm, updateFormValue] = useForms(initialForm);
  const [cpfCnpj, setCpfCpnj] = useState('');
  const [address, setAddress] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [expenseType, setExpenseType] = useState(null);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [transactionNature, setTransactionNature] = useState({});
  const [transactionNatures, setTransactionNatures] = useState([]);
  const [colaborator, setColaborator] = useState(null);
  const [sellServices, setSellServices] = useState([]);
  const [sellClassifications, setSellClassifications] = useState([]);
  const [planAccount, setPlanAccount] = useState({});
  const [planAccounts, setPlanAccounts] = useState([]);
  const [centerCost, setCenterCost] = useState({});
  const [centerCosts, setCenterCosts] = useState([]);
  const [paymentForms, setPaymentForms] = useState([]);
  const [paymentForm, setPaymentForm] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState({});
  const [hidePaymentForm, setHidePaymentForm] = useState(false);
  const [classification, setClassification] = useState({});
  const [sellPayments, setSellPayments] = useState([]);
  const [parcelNumber, setParcelNumber] = useState(null);
  const [parcelType, setParcelType] = useState(null);
  const [parcelTypes, setParcelTypes] = useState([]);
  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);
  const [frequency, setFrequency] = useState(null);
  const [frequencies, setFrequencies] = useState([]);
  const [popUpFile, setPopUpFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [totalSalary, setTotalSalary] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [popUpConfirm, setPopUpConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDiv, setLoadingDiv] = useState(false);
  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const [loadingSupplier, setLoadingSupplier] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [loadingClassifications, setLoadingClassifications] = useState(false);

  const [popUpsVisibilities, setPopUpsVisibilities] = useState({
    addPayRoll: false,
    addPayment: false,
    addClassification: false,
    divideClassification: false
  });

  const calculateTotalService = (newSellServices) => {
    const _sellServices = !newSellServices ? sellServices : newSellServices;
    let totalSalary = _sellServices.reduce((acc, current) => acc + current.Valor, 0);
    totalSalary = Math.round(totalSalary * 100) / 100;
    setTotalSalary(totalSalary);
    let totalDiscount = _sellServices.reduce((acc, current) => acc + current.Desconto, 0);
    totalDiscount = Math.round(totalDiscount * 100) / 100;
    setTotalDiscount(totalDiscount);
  };

  const addClassification = (classifications) => {
    const index = sellClassifications.findIndex((item) => item.Id === classifications[0].Id);
    sellClassifications.splice(index, 1, classifications[0]);
    for (let i = 1; i < classifications.length; i++) {
      sellClassifications.splice(index + i, 0, classifications[i]);
    }
    for (let i = 0; i < sellClassifications.length; i++) {
      sellClassifications.Id = i + 1;
    }
    setSellClassifications([...sellClassifications]);
  };

  const resetPayments = () => {
    setForm({
      ...form,
      DataVencimento: DateNow()
    });
    setParcelNumber(null);
    setFrequency(null);
    setParcelType(parcelTypes[0]);
    setSellPayments([]);
  };

  const updateClassification = (newSellServices = null) => {
    let id = 1;
    const newClassifications = [];
    const _sellServices = newSellServices != null ? newSellServices : sellServices;
    _sellServices.forEach((service) => {
      const classification = {
        Id: id,
        Descricao: service.DescricaoServProd,
        Valor: service.ValorTotal
      };
      newClassifications.push(classification);
      id++;
    });
    setSellClassifications(newClassifications);
    return newClassifications;
  };

  const onItemChangeSellService = (item) => {
    item.ValorTotal = item.Quantidade * item.Valor - item.Desconto;
    calculateTotalService();
    resetPayments();
    updateClassification();
    return item;
  };

  const onItemDeleteSellService = () => {
    calculateTotalService();
    resetPayments();
    updateClassification();
  };

  const setPopUpClassification = (classification) => {
    setPopUpsVisibilities({
      ...popUpsVisibilities,
      divideClassification: true
    });
    setClassification(classification);
  };

  const setPopUpVisibility = (propName, value) => {
    setPopUpsVisibilities({
      ...popUpsVisibilities,
      [propName]: value
    });
  };

  const setColaboratorAddress = async (colaborator, idAddress) => {
    if (colaborator.TipoPessoa === 0) {
      setCpfCpnj(colaborator.CPF);
      setAddress(colaborator.Endereco);
    } else {
      let addresses = [];
      if (colaborator.EnderecoPrincipal != null) addresses.push(colaborator.EnderecoPrincipal);
      addresses = addresses.concat(colaborator.OutrosEnderecos);
      addresses = addresses.map((item) => ({
        ...item,
        EnderecoCompleto: [item.Logradouro, item.Numero, item.Complemento, item.Bairro]
          .filter(Boolean)
          .join(', ')
      }));
      let newAddress = addresses.filter((x) => x.Id === idAddress);
      newAddress = newAddress.length > 0 ? newAddress[0] : null;
      if (newAddress != null) {
        setCpfCpnj(newAddress.CNPJ ? newAddress.CNPJ : '');
        setAddress(newAddress);
      } else if (addresses.length === 1) {
        setCpfCpnj(addresses[0].CNPJ ? addresses[0].CNPJ : '');
        setAddress(addresses[0]);
      }
      setAddresses(addresses);
    }
  };

  const loadFrequencies = async () => {
    const { data } = await humanResourceService.getFrequencies();
    setFrequencies(data);
    return data;
  };

  const loadParcelTypes = async () => {
    const { data } = await humanResourceService.getParcelTypes();
    setParcelTypes(data);
    return data;
  };

  const loadExpenseTypes = async () => {
    const { data } = await humanResourceService.getHumanResourceTypes();
    setExpenseTypes(data);
    return data;
  };

  const loadClients = async (filter) => {
    const { data } = await personService.getByCollaboratorName(filter);
    return data;
  };

  const loadPaymentForms = async () => {
    let { data } = await transactionService.getPaymentForms();
    data = data['000'];
    data = data.filter((item) => item.Id !== '2' && item.Id !== '3');
    data.sort((a, b) => {
      if (a.Descricao < b.Descricao) {
        return -1;
      }
      if (a.Descricao > b.Descricao) {
        return 1;
      }
      return 0;
    });
    setPaymentForms(data);
    return data;
  };

  const loadAccounts = async () => {
    const data = await accountService.getReceivingLocal();
    setAccounts(data);
    return data;
  };

  const loadPlanAccounts = async () => {
    const data = await planAccountService.getAllExpensesRevenues();
    setPlanAccounts(data);
    return data;
  };

  const loadCenterCosts = async () => {
    const { data } = await centerCostService.getAll();
    setCenterCosts(data);
    return data;
  };

  const loadBrazil = async () => {
    const { data } = await countryService.getAll();
    const brazil = data.filter((p) => p.Descricao === 'Brasil')[0];
    return brazil;
  };

  const loadStates = async (id) => {
    const { data } = await addressService.statesFromCountry(id);
    setStates(data);
    return data;
  };

  const loadCities = async (id) => {
    const { data } = await addressService.citiesFromState(id);
    setCities(data);
    return data;
  };

  const redirectToExpenses = (id) => {
    if (id) history.push(`/humanResources/${id}`);
    else history.push(`/humanResources`);
  };

  const generateDividedTransaction = () => {
    const dividedTransactions = [];
    sellClassifications.forEach((element) => {
      dividedTransactions.push({
        Descricao: element.Descricao,
        Valor: element.Valor,
        IdPlanoDeContas: element.PlanoDeContas?.Id,
        IdCentroDeCustos: element.CentroDeCustos?.Id
      });
    });
    return dividedTransactions;
  };

  const generateTransaction = () => {
    let total = sellServices.reduce((acc, current) => acc + current.ValorTotal, 0);
    total = Math.round(total * 100) / 100;
    let totalClassications = sellClassifications.reduce((acc, current) => acc + current.Valor, 0);
    totalClassications = Math.round(totalClassications * 100) / 100;
    const newDate = form.DataVencimento;
    const transaction = {
      Data: newDate,
      DataEmissao: form.Data,
      DataVencimento: newDate,
      Descricao: `Venda ${form.Numero}`,
      Valor: totalClassications,
      IdFormaPagamento: paymentForm?.Id,
      IdContaPagamento: account?.Id,
      NumeroParcelas: parcelNumber,
      listaLancamentoDivisao: generateDividedTransaction()
    };
    return transaction;
  };

  const generateClassifications = () => {
    const classifications = sellClassifications.map((x) => ({
      Descricao: x.Descricao,
      PlanoDeContasId: x.PlanoDeContas?.Id,
      CentroDeCustosId: x.CentroDeCustos?.Id,
      Valor: x.Valor
    }));
    return classifications;
  };

  const generatePayments = () => {
    const payments = sellPayments.map((x) => ({
      DataVencimento: new Date(x.DataVencimento),
      Valor: x.Valor,
      FormaPagamento: x.FormaPagamento?.Id,
      ContaId: x.ContaPagamento?.Id
    }));
    return payments;
  };

  const addPayments = (period, parcel, paymentForm, account, date, classifications) => {
    if (parcelType?.Id === 2) {
      if (period && parcel && date) {
        const newSellPayments = [];
        classifications = classifications || generateClassifications();
        const sum = classifications.reduce((partialSum, item) => partialSum + item.Valor, 0);
        let newDate = new Date(date);
        const values = numberUtil.parcel(sum, parcel);
        for (let i = 0; i < parcel; i++) {
          newSellPayments.push({
            Id: i,
            DataVencimento: new Date(newDate),
            Valor: values[i],
            FormaPagamento: paymentForm,
            ContaPagamento: account
          });
          newDate = calculateNewDateFrequency(newDate, period.Id);
        }
        setSellPayments(newSellPayments);
      } else {
        setSellPayments([]);
      }
    } else {
      setSellPayments([]);
    }
    return true;
  };

  const addPayment = () => {
    if (sellPayments.length > 0) {
      const last = sellPayments[sellPayments.length - 1];
      setSellPayments([
        ...sellPayments,
        {
          Id: last.Id + 1,
          DataVencimento: last.DataVencimento,
          Valor: last.Valor,
          FormaPagamento: last.FormaPagamento,
          ContaPagamento: last.ContaPagamento
        }
      ]);
    } else {
      setSellPayments([
        {
          Id: 1,
          DataVencimento: DateNow(),
          Valor: 0,
          FormaPagamento: null,
          ContaPagamento: null
        }
      ]);
    }
  };

  const loadSellClassifications = (expense, planAccounts, centerCosts) => {
    let sellClassifications = expense.DespesaClassificacao;
    let i = 0;
    sellClassifications = sellClassifications.map((x) => {
      i++;
      const itemPlanAccount = planAccounts.find((element) => element.Id === x.PlanoDeContasId);
      const itemCenterCost = centerCosts.find((element) => element.Id === x.CentroDeCustosId);
      return {
        Id: i,
        ...x,
        PlanoDeContas: {
          Id: itemPlanAccount?.Id,
          Nome: itemPlanAccount?.Nome
        },
        CentroDeCustos: {
          Id: itemCenterCost?.Id,
          Nome: itemCenterCost?.Nome
        }
      };
    });
    setSellClassifications(sellClassifications);
    if (sellClassifications.length === 1) {
      setPlanAccount(sellClassifications[0].PlanoDeContas);
      setCenterCost(sellClassifications[0].CentroDeCustos);
    }
  };

  const loadSellPayments = (expense, paymentForms, accounts) => {
    let sellPayments = expense.DespesaPagamento;
    let i = 0;
    sellPayments = sellPayments.map((x) => {
      i++;
      const itemPaymentForm = paymentForms.find((element) => element.Id === x.FormaPagamento);
      const itemAccount = accounts.find((element) => element.Id === x.ContaId);
      return {
        Id: i,
        DataVencimento: new Date(x.DataVencimento),
        Valor: x.Valor,
        FormaPagamento: itemPaymentForm,
        ContaPagamento: itemAccount
      };
    });
    setSellPayments(sellPayments);
  };

  const addPayRoll = (payRoll) => {
    const id = sellServices.length > 0 ? sellServices[sellServices.length - 1].Id + 1 : 1;
    const newPayRoll = {
      Id: id,
      ...payRoll
    };
    const newSellServices = [...sellServices, newPayRoll];
    setSellServices(newSellServices);
    calculateTotalService(newSellServices);
    updateClassification(newSellServices);
  };

  const createAttachs = async (id) => {
    const attachRequests = files.map((file) => expenseService.createFile(id, file.getRawFile()));
    const attachResponses = await Promise.all(attachRequests);

    const isAllRequestsRight = attachResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    if (!isAllRequestsRight) {
      const erro = ['Houve um erro ao tentar anexar uma ou mais arquivos:'];
      attachResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      return erro;
    }
    return null;
  };

  useEffect(async () => {
    setLoadingSupplier(true);
    setLoadingGeneral(true);
    setLoadingServices(true);
    setLoadingPayments(true);
    setLoadingClassifications(true);
    const brazil = await loadBrazil();
    const states = await loadStates(brazil?.Id);
    if (id && id !== '0') {
      let response = await humanResourceService.getById(id);
      const expense = response.data;
      // ############## Load General ##############
      setForm({
        Numero: expense.Numero,
        Data: new Date(expense.Data),
        DataVencimento: new Date(expense.DataVencimento),
        InformacaoAdicional: expense.InformacaoAdicional,
        LancamentoBaixado: expense.LancamentoBaixado
      });

      const expenseTypes = await loadExpenseTypes();
      const expenseType = expenseTypes.find((item) => item.Id === expense.TipoDespesa);
      setExpenseType(expenseType);

      if (expense.MunicipioId) {
        const city = await addressService.getCityById(expense.MunicipioId);
        if (city.data) {
          const { data } = city;
          await loadCities(data.idEstado);
          setCity(data);
          const state = states.find((item) => item.idEstado === data.idEstado);
          setState(state);
        }
      }
      setTransactionNature(expense.NaturezaOperacao);
      setLoadingGeneral(false);

      // ############## Load Client ##############
      response = await personService.getById(expense.PessoaId);
      const client = response.data;
      setColaborator(client);
      setColaboratorAddress(client, expense.EnderecoId);
      setLoadingSupplier(false);

      // ############## Load Services and Products ##############
      const sellServices = expense.DespesaServProd.map((item) => ({
        ...item,
        Valor: item.Valor === 0 ? null : item.Valor,
        Desconto: item.Desconto === 0 ? null : item.Desconto
      }));
      setSellServices(sellServices);
      calculateTotalService(sellServices);
      setLoadingServices(false);

      // ############## Load Payments ##############
      const paymentForms = await loadPaymentForms();
      const accounts = await loadAccounts();
      const parcelTypes = await loadParcelTypes();
      const frequencies = await loadFrequencies();
      loadSellPayments(expense, paymentForms, accounts);
      const itemAccount = accounts.find((element) => element.Id === expense.ContaId);
      setAccount(itemAccount);
      const itemPaymentForm = paymentForms.find(
        (element) => element.Id === expense.FormaPagamento?.toString()
      );
      setPaymentForm(itemPaymentForm);
      const parcelType = parcelTypes.find((element) => element.Id === expense.TipoPagamento);
      setParcelType(parcelType);
      const frequency = frequencies.find((element) => element.Id === expense.Periodicidade);
      setFrequency(frequency);
      setParcelNumber(expense.NumeroParcela);
      setLoadingPayments(false);

      // ############## Load Classifications ##############
      const planAccounts = await loadPlanAccounts();
      const centerCosts = await loadCenterCosts();
      loadSellClassifications(expense, planAccounts, centerCosts);
      setLoadingClassifications(false);
    } else {
      // ############## Load General ##############
      const expenseTypes = await loadExpenseTypes();
      setExpenseType(expenseTypes[0]);
      setLoadingGeneral(false);

      // ############## Load Client ##############
      setLoadingSupplier(false);

      // ############## Load Services ##############
      setLoadingServices(false);

      // ############## Load Payments ##############
      const paymentForms = await loadPaymentForms();
      const accounts = await loadAccounts();
      const parcelTypes = await loadParcelTypes();
      const frequencies = await loadFrequencies();
      setParcelType(parcelTypes[0]);
      setFrequency(null);
      setLoadingPayments(false);

      // ############## Load Classifications ##############
      const planAccounts = await loadPlanAccounts();
      const centerCosts = await loadCenterCosts();
      setLoadingClassifications(false);
    }
  }, []);

  const validate = async () => {
    if (
      loading ||
      loadingDiv ||
      loadingGeneral ||
      loadingSupplier ||
      loadingServices ||
      loadingPayments ||
      loadingClassifications
    ) {
      warningNotification('Aguarde o carregamento dos dados.');
      return false;
    }

    if (sellServices.length === 0) {
      warningNotification('Pelo menos um servíço deve ser adicionado.');
      return false;
    }
    for (const item of sellServices) {
      if (!item.DescricaoServProd) {
        warningNotification('As descrições dos serviços/produtos devem ser adicionadas.');
        return false;
      }
    }
    if (parcelType?.Id === 2) {
      if (!parcelNumber || parcelNumber <= 0 || parcelNumber > 1000) {
        warningNotification('Número de parcelas inválido.');
        return false;
      }
      if (!frequency) {
        warningNotification('Periodicidade invalida.');
        return false;
      }
      const payments = generatePayments();
      let sum = payments.reduce((partialSum, item) => partialSum + item.Valor, 0);
      sum = Math.round(sum * 100) / 100;
      const classifications = generateClassifications();
      let sumClassifications = classifications.reduce(
        (partialSum, item) => partialSum + item.Valor,
        0
      );
      sumClassifications = Math.round(sumClassifications * 100) / 100;
      if (sumClassifications !== sum) {
        warningNotification('Valor líquido é diferente do valor da classificação.');
        return false;
      }
    }
    return true;
  };

  const submitFormValidated = async () => {
    setLoading(true);
    const sum = sellServices.reduce((partialSum, item) => partialSum + item.Valor, 0);
    const transaction = !hidePaymentForm ? generateTransaction() : null;
    const classifications = generateClassifications();
    const sumClassifications = classifications.reduce(
      (partialSum, item) => partialSum + item.Valor,
      0
    );
    const payments = parcelType?.Id === 2 ? generatePayments() : null;
    const bodyForm = {
      ...form,
      FormaPagamento: paymentForm?.Id,
      ContaId: account?.Id,
      Valor: sum,
      PessoaId: colaborator.Id,
      DespesaServProd: sellServices,
      DespesaClassificacao: classifications,
      Lancamento: transaction,
      ValorLiquido: sumClassifications,
      NaturezaOperacaoId: transactionNature?.Id,
      MunicipioId: city?.idMunicipio,
      EnderecoId: address?.Id,
      TipoDespesa: expenseType?.Id,
      DespesaPagamento: payments,
      NumeroParcela: parcelNumber,
      Periodicidade: frequency?.Id,
      TipoPagamento: parcelType?.Id
    };
    let response = null;
    if (id && id !== '0') {
      response = await humanResourceService.update(id, bodyForm);
    } else {
      response = await humanResourceService.create(bodyForm);
      const { data } = response;
      id = data;
    }
    if (response.status === 200) {
      const erro = await createAttachs(id);
      if (erro) errorNotification(erro);
      else successNotification('Recurso Humano criado/atualizado com sucesso!');
      redirectToExpenses(id);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
      else errorNotification(data);
    }
    setLoading(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (await validate()) {
      if (form.LancamentoBaixado) setPopUpConfirm(true);
      else submitFormValidated();
    }
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="expense-registration">
        {id && id !== '0' ? <h1>Atualizar Recursos Humanos</h1> : <h1>Novo Recursos Humanos</h1>}
        <div className="buttons-expense-registration">
          <div className="config-components-popup-button">
            <FloatingActionButton
              icon={INTERNAL_BUTTON.BUTTON_ATTACH}
              onClick={() => {
                setPopUpFile(true);
              }}
            />
          </div>
        </div>
        <form className="form-general-expense" onSubmit={submitForm}>
          <ToggleExpense title="Dados Gerais" startExpanded>
            {loadingGeneral ? (
              <LoadScreen />
            ) : (
              <>
                <FormRow withShrink>
                  <FormFieldDrop
                    titleLabel="Tipo de Documento"
                    defaultValueSelect="Selecione..."
                    value={expenseType}
                    onChangeValue={(val) => {
                      setExpenseType(val.value);
                    }}
                    className="fill-40-field"
                    infosSelect={expenseTypes}
                    dataItemKey="Id"
                    textField="Nome"
                    required
                    disabled
                  />
                  <FormFieldDate
                    titleLabel="Data de Emissão"
                    value={form.Data}
                    valueOnChange={(val) => updateFormValue('Data', val)}
                    className="fill-40-field"
                    required
                  />
                  <GenericField
                    titleLabel="Número"
                    enableInfo
                    onChangeValue={updateProperty}
                    valueInput={form.Numero}
                    classNameWrapper="fill-40-field"
                    name="Numero"
                  />
                </FormRow>
              </>
            )}
          </ToggleExpense>
          <ToggleExpense title="Colaborador" startExpanded>
            {loadingSupplier ? (
              <LoadScreen />
            ) : (
              <>
                <Supplier
                  supplier={colaborator}
                  setSupplier={setColaborator}
                  cpfCnpj={cpfCnpj}
                  setCpfCpnj={setCpfCpnj}
                  address={address}
                  setAddress={setAddress}
                  addresses={addresses}
                  setAddresses={setAddresses}
                  isSupplier={false}
                  supplierName="Colaborador"
                />
              </>
            )}
          </ToggleExpense>
          <ToggleExpense title="Descrição" startExpanded>
            {loadingServices ? (
              <LoadScreen />
            ) : (
              <>
                <FormRow>
                  <GridInline
                    data={sellServices}
                    setData={setSellServices}
                    onItemChange={onItemChangeSellService}
                    deleteColumn={true}
                    onItemDelete={onItemDeleteSellService}
                  >
                    <GridColumn field="Codigo" title="Código" editable={true} />
                    <GridColumn field="DescricaoServProd" title="Descrição" editable={true} />
                    <GridColumn field="Referencia" title="Referência" editable={true} />
                    <GridColumn
                      field="Valor"
                      title="Vencimento"
                      editable={true}
                      editor="numeric"
                      cell={NumberCell}
                      footerCell={(props) => TotalValueCell(props, totalSalary)}
                    />
                    <GridColumn
                      field="Desconto"
                      title="Desconto"
                      editable={true}
                      editor="numeric"
                      cell={NumberCell}
                      footerCell={(props) => TotalValueCell(props, totalDiscount)}
                    />
                  </GridInline>
                </FormRow>
                <FormRow justifyEnd space10>
                  <OrangeButton onClick={() => setPopUpVisibility('addPayRoll', true)}>
                    Adicionar
                  </OrangeButton>
                </FormRow>
              </>
            )}
          </ToggleExpense>
          <ToggleExpense title="Forma de Pagamento" startExpanded>
            {loadingPayments ? (
              <LoadScreen />
            ) : (
              <>
                <FormRow withShrink>
                  <FormFieldDrop
                    titleLabel="Forma de Pagamento"
                    defaultValueSelect="Selecione..."
                    onChangeValue={(val) => {
                      setPaymentForm(val.value);
                    }}
                    value={paymentForm}
                    className="fill-50-field"
                    infosSelect={paymentForms}
                    dataItemKey="Id"
                    textField="Descricao"
                    name="FormaPagamento"
                  />
                  <FormFieldDrop
                    titleLabel="Local de Pagamento"
                    defaultValueSelect="Selecione..."
                    onChangeValue={(val) => {
                      setAccount(val.value);
                      addPayments(
                        frequency,
                        parcelNumber,
                        paymentForm,
                        val.value,
                        form.DataVencimento
                      );
                    }}
                    value={account}
                    className="fill-50-field"
                    infosSelect={accounts}
                    dataItemKey="Id"
                    textField="Nome"
                  />
                </FormRow>
                <FormRow withShrink>
                  <FormFieldDrop
                    titleLabel="Tipo de Parcelamento"
                    defaultValueSelect="Selecione..."
                    onChangeValue={(val) => {
                      setParcelType(val.value);
                      setFrequency(null);
                    }}
                    value={parcelType}
                    className="fill-30-field"
                    infosSelect={parcelTypes}
                    dataItemKey="Id"
                    textField="Nome"
                    required
                  />
                  {parcelType?.Id === 2 && (
                    <>
                      <GenericField
                        titleLabel="Número de Parcelas"
                        onChangeValue={(val) => {
                          const {
                            target: { value }
                          } = val;
                          addPayments(frequency, value, paymentForm, account, form.DataVencimento);
                          setParcelNumber(value);
                        }}
                        valueInput={parcelNumber}
                        classNameWrapper="fill-30-field"
                        typeNumber
                      />
                      <FormFieldDrop
                        titleLabel="Periodicidade"
                        defaultValueSelect="Selecione..."
                        onChangeValue={(val) => {
                          const {
                            target: { value }
                          } = val;
                          addPayments(
                            value,
                            parcelNumber,
                            paymentForm,
                            account,
                            form.DataVencimento
                          );
                          setFrequency(value);
                        }}
                        value={frequency}
                        className="fill-30-field"
                        infosSelect={frequencies}
                        dataItemKey="Id"
                        textField="Nome"
                      />
                    </>
                  )}
                </FormRow>
                <FormRow withShrink>
                  <FormFieldDate
                    titleLabel="Data de Referência"
                    value={form.DataVencimento}
                    valueOnChange={(val) => {
                      updateFormValue('DataVencimento', val);
                      addPayments(frequency, parcelNumber, paymentForm, account, val);
                    }}
                    className="fill-30-field"
                    required
                  />
                </FormRow>
                {parcelType?.Id === 2 && (
                  <>
                    <FormRow withShrink>
                      <GridInline data={sellPayments} setData={setSellPayments} deleteColumn={true}>
                        <GridColumn
                          field="DataVencimento"
                          title="Vencimento"
                          editable={true}
                          cell={DateCell}
                          footerCell={(props) => TotalTextCell(props)}
                        />
                        <GridColumn
                          field="Valor"
                          title="Valor a Pagar"
                          editor="numeric"
                          cell={NumberCell}
                          editable={true}
                          footerCell={(props) => TotalCell(props, sellPayments, 'Valor')}
                        />
                        <GridColumn
                          field="FormaPagamento"
                          title="Forma de Pagamento"
                          editable={true}
                          cell={(props) => DropDownCell(props, paymentForms, 'Id', 'Descricao')}
                        />
                        <GridColumn
                          field="ContaPagamento"
                          title="Local de Pagamento"
                          editable={true}
                          cell={(props) => DropDownCell(props, accounts, 'Id', 'Nome')}
                        />
                      </GridInline>
                    </FormRow>
                    <FormRow justifyEnd space10>
                      <OrangeButton
                        onClick={() => {
                          addPayment();
                        }}
                      >
                        Adicionar Parcela
                      </OrangeButton>
                    </FormRow>
                  </>
                )}
              </>
            )}
          </ToggleExpense>
          <ToggleExpense title="Classificação" startExpanded>
            {loadingClassifications ? (
              <LoadScreen />
            ) : (
              <>
                <FormRow withShrink>
                  <GridRowSpan
                    data={sellClassifications}
                    setData={setSellClassifications}
                    commandColumn={true}
                    popUpCommandColumn={(val) => setPopUpClassification(val)}
                    rowSpanField="Descricao"
                  >
                    <GridColumn field="Descricao" title="Descrição" editable={true} />
                    <GridColumn
                      field="PlanoDeContas"
                      title="Plano de Contas"
                      editable={true}
                      cell={(props) => DropDownCell(props, planAccounts, 'Id', 'Nome')}
                    />
                    <GridColumn
                      field="CentroDeCustos"
                      title="Centro de Custo"
                      editable={true}
                      cell={(props) => DropDownCell(props, centerCosts, 'Id', 'Nome')}
                      footerCell={(props) => TotalTextCell(props)}
                    />
                    <GridColumn
                      field="Valor"
                      title="Valor"
                      editor="numeric"
                      cell={NumberCell}
                      editable={true}
                      footerCell={(props) => TotalCell(props, sellClassifications, 'Valor')}
                    />
                  </GridRowSpan>
                </FormRow>
              </>
            )}
          </ToggleExpense>
          <FormRow justifyEnd space15 marginTop40>
            <OrangeButton type="submit" name="salvar">
              Salvar
            </OrangeButton>
            <WhiteButton onClick={() => redirectToExpenses(id)}>Cancelar</WhiteButton>
          </FormRow>
        </form>
      </div>
      <PopUpAddPayRoll
        visible={popUpsVisibilities.addPayRoll}
        setVisible={(val) => setPopUpVisibility('addPayRoll', val)}
        addPayRoll={(payRoll) => addPayRoll(payRoll)}
      />
      <PopupAddClassification
        visible={popUpsVisibilities.addClassification}
        setVisible={(val) => setPopUpVisibility('addClassification', val)}
        addClassification={(classification) => addClassification(classification)}
        planAccounts={planAccounts}
        centerCosts={centerCosts}
      />
      <PopupDivideClassification
        visible={popUpsVisibilities.divideClassification}
        setVisible={(val) => setPopUpVisibility('divideClassification', val)}
        addClassification={(classifications) => addClassification(classifications)}
        planAccounts={planAccounts}
        centerCosts={centerCosts}
        classification={classification}
      />
      <PopUpFiles
        visible={popUpFile}
        setVisible={setPopUpFile}
        title="Anexos"
        id={id && id !== '0' ? id : null}
        setExternalFiles={setFiles}
        service={expenseService}
      />
      <PopUpConfirm
        onConfirm={submitFormValidated}
        visible={popUpConfirm}
        setVisible={setPopUpConfirm}
        title="Atualizar Recursos Humanos"
        message="Existem transações baixadas para este documento no NEXTFinance. Todas as transações do NEXTFinance serão substituídas e retornarão para o contas a pagar. Deseja continuar?"
      />
    </>
  );
};

export default HumanRegistration;
