import PropTypes from 'prop-types';
import React from 'react';
import '../style.css';
import './style.css';

const SimpleGrayButton = ({ children, ...other }) => (
  <div {...other} className="simple-gray-button box-button general-button">
    {children}
  </div>
);

SimpleGrayButton.propTypes = {
  children: PropTypes.string.isRequired
};

export default SimpleGrayButton;
